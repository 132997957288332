import {
  PURCHASES_START,
  PURCHASES_SUCCESS,
  PURCHASES_FILED,
} from "../action-types/actionTypes";
import { api } from "../../api/service";

export const purchasesCreate = (data) => {
  return async (dispatch) => {
    dispatch({
      type: PURCHASES_START,
    });
    try {
      const response = await api.post("/purchases", data);
      dispatch({
        type: PURCHASES_SUCCESS,
        payload: response,
      });
    } catch (e) {
      dispatch({
        type: PURCHASES_FILED,
        error: { message: "Something went wrong." },
      });
    }
  };
};
