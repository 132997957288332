import React from 'react'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import './style.scss'

export  function DeleteButton({handleClick}) {
    return (
        <div>
             <button className='del-btn' onClick={handleClick} >
                <DeleteForeverIcon  style={{ color: 'white' }} fontSize='small' />
            </button>
        </div>
    )
}
