import {
    GET_GAME_DETALIS_START,
    GET_GAME_DETALIS_SUCCESS,
    GET_GAME_DETALIS_FILED,
} from "../action-types/actionTypes";


const initialState = {
    loading: false,
    gameDetalis: null,
    error: null,
}

export function gameDetalisReduser(state = initialState, action) {
    switch (action.type) {
        case GET_GAME_DETALIS_START:
            return {
                ...state,
                loading: true
            }
        case GET_GAME_DETALIS_SUCCESS:
            return {
                ...state,
                loading: false,
                gameDetalis: action.payload,
               }
        case GET_GAME_DETALIS_FILED:
            return {
                ...state,
                loading: false,
                error: 'samething went wrong',
            }
        default:
            return state;
    }
}


