import React, {useState,useEffect} from 'react'
import { Pagination } from '../Pagination/Pagination'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import './style.scss'


export function Table({
    thArr,
    renderList,
    pageCount,
    selectPage,
    onChangePage,
    styles = {fontWeight: 600} }) {

        const [isSortCreatedAt, setSortCreatedAt] = useState(false)
        const [isSortUpdatedAt, setSortUpdatedAt] = useState(false)
        useEffect(()=>{
            renderList && renderList.sort((a, b) => new Date(a) < new Date(b) ? 1 : -1)
        },[])
        
        const handleSort = (val) => {
            if(val === 'CreatedAt'){
                setSortCreatedAt(!isSortCreatedAt)
                if (isSortCreatedAt) {
                    renderList.sort((a, b) => new Date(a) < new Date(b) ? 1 : -1)
                } else {
                    renderList.sort((a, b) => new Date(a) > new Date(b) ? 1 : -1)
                }
            }
            if(val === 'UpdatedAt'){
                setSortUpdatedAt(!isSortUpdatedAt)
                if (isSortUpdatedAt) {
                    renderList.sort((a, b) => new Date(a) < new Date(b) ? 1 : -1)
                } else {
                    renderList.sort((a, b) => new Date(a) > new Date(b) ? 1 : -1)
                }
            }
         }
  

    return (
 <table className="table">
            <thead>
                <tr className="sortable" >
                    {thArr.map((el, i) => (
                        <th style={styles} key={i} onClick ={(val)=>handleSort(el)}> {el}
                        {el === 'CreatedAt' && 
                        (!isSortCreatedAt ? <ArrowDropDownIcon style={{ verticalAlign: 'middle' }} fontSize={'small'} /> :
                        <ArrowDropUpIcon style={{ verticalAlign: 'middle' }} fontSize={'small'} />)}
                        {el === 'UpdatedAt' && 
                        (!isSortUpdatedAt? <ArrowDropDownIcon style={{ verticalAlign: 'middle' }} fontSize={'small'} /> :
                        <ArrowDropUpIcon style={{ verticalAlign: 'middle' }} fontSize={'small'} />)}
                          </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {renderList}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan="6" className="text-center" >
                        <div className="nav">
                            {pageCount > 1 && <Pagination
                                selectPage={selectPage}
                                pageCount={pageCount}
                                onChangePage={(i) => onChangePage(i)}
                            />}
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>

    )
}
