
export function getDays(day) {
    let d = new Date(day)
    let date = d.toDateString() + ' ' + d.toTimeString()
    date = date.slice(3).substring(0, 22)
    
    return date
}

export function createColorFromStatus(status, color) {
    if (status === 0) {
        color = '#de1d10'
    } else if (status === 1) {
        color = '#5165e8'
    } else if (status === 2) {
        color = '#f7cb2a'
    } else if (status === 3) {
        color = '#ff6a19'
    } else if (status === 4) {
        color = '#30ba40'
    }
    return color
}

export  function toIso(date) {
    date.setHours(4)
    date.toISOString()
    return date
}