import {
    PURCHASES_START,
    PURCHASES_SUCCESS,
    PURCHASES_FILED,
} from "../action-types/actionTypes";


const initialState = {
    loadingPurch: false,
    purchases: null,
    error: null,
}

export function purchaseReducer(state = initialState, action) {
    switch (action.type) {
        case PURCHASES_START:
            return {
                ...state,
                loadingPurch: true
            }
        case PURCHASES_SUCCESS:
            return {
                ...state,
                loadingPurch: false,
                purchases: action.payload.data,
               }
        case PURCHASES_FILED:
            return {
                ...state,
                loadingPurch: false,
                error: 'samething went wrong',
            }
        default:
            return state;
    }
}