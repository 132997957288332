import React, { useEffect } from 'react';
import { ok, fb, google, ios, vk, android } from "../../assets";
import { useSelector, useDispatch } from 'react-redux';
import { newUsersCreate } from '../../store/actions/newUsers';
import './style.scss';

export function NewUsers() {

    const stateNewUsers = useSelector(state => state.newUsersReducer);
    const { newUsersData } = stateNewUsers;
    const dispatch = useDispatch();
    useEffect(() => {
        if (!newUsersData) dispatch(newUsersCreate());
    }, []);

    return (

        <table className='purchase-container'>
            <tbody>
                <tr>
                    <th className="font-default">New Users</th>
                </tr>
                <tr className='row-purchase'>
                    <td className="al-user-profile">
                        <img src={fb} alt="fb" />
                    </td>
                    <td>
                        <p className="center-text">{newUsersData && Object.keys(newUsersData).length && newUsersData.fb && newUsersData.fb.length}</p>
                    </td>
                </tr>
                <tr className='row-purchase'>
                    <td className="al-user-profile">
                        <img src={ok} alt="ok" />
                    </td>
                    <td>
                        <p className="center-text">{newUsersData && Object.keys(newUsersData).length && newUsersData.ok && newUsersData.ok.length}</p>
                    </td>
                </tr>

                <tr className='row-purchase'>
                    <td className="al-user-profile">
                        <img src={vk} alt="vk" />
                    </td>
                    <td>
                        <p className="center-text">{newUsersData && Object.keys(newUsersData).length && newUsersData.vk && newUsersData.vk.length}</p>
                    </td>
                </tr>
                <tr className='row-purchase'>
                    <td className="al-user-profile">
                        <img src={android} alt="android" />
                        <img src={ios} alt="ios" style={{ height: '115%' }} />
                    </td>
                    <td>
                        <p className="center-text">{newUsersData && Object.keys(newUsersData).length && newUsersData.native && newUsersData.native.length}</p>
                    </td>
                </tr>
            </tbody>
        </table>

    );
}
