import React from 'react'
import './style.scss'

export function Button({ text, handleClick, className='btn-comp' }) {
    return (
        <button className={className} onClick={handleClick}>{text}</button>
    )
}




