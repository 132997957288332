import React from 'react'
import ReactPaginate from 'react-paginate';
import './style.scss'




export function Pagination({ onChangePage, pageCount, selectPage }) {
   
    return (
        <ReactPaginate
            onPageChange={(i) => onChangePage(i)}
            pageCount={pageCount}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            disableInitialCallback={true}
            forcePage={selectPage}
        />

    )
}
