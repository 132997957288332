
import {
    SEARCH_START_USER,
    SEARCH_SUCCESS_USER,
    SEARCH_FILED_USER,
} from "../action-types/actionTypes";


const initialState = {
    loading: false,
    userData: null,
    error: null,
    showSearch: false,
    isChangeLocation: false
}

export function userSearch(state = initialState, action) {
    switch (action.type) {
        case SEARCH_START_USER:
            return {
                ...state,
                loading: true
            }
        case SEARCH_SUCCESS_USER:
            return {
                ...state,
                loading: false,
                userData: action.payload.users,
                showSearch: true
            }
        case SEARCH_FILED_USER:
            return {
                ...state,
                loading: false,
                error: 'samething went wrong',
            }
            case "TO_ZERO":{
                return {
                    ...state,
                    userData: []
                }
            }
            case "CHANGE_LOCATION_TRUE" :{
                return {
                    ...state,
                    isChangeLocation: true
                }
            }
            case "CHANGE_LOCATION_FALSE" :{
                return {
                    ...state,
                    isChangeLocation: false
                }
            }
        default:
            return state;
    }
}


