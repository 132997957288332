import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Checkbox from '@material-ui/core/Checkbox';
import { yellowChipIcon, greenChipIcon, redChipIcon, vk, ok, fb, ios, android } from '../../assets'
import { updateNotificationCreate, createNotificationCreate, addListCreateNotificationCreate, addUpdateNotificationCreate } from '../../store/actions/notification'
import { Icon, } from '@iconify/react';
import closeIcon from '@iconify/icons-iwwa/close';
import './style.scss'


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '5px',
        border: 'none !important',
        boxShadow: theme.shadows[5],
        width: 900,
        padding: '0px 2px'
    },
    title: {
        color: ' #7d7d7d !important',
        marginLeft: '15px',
        fontWeight: '500',
    },
    inputWrap: {
        position: 'relative',
        padding: '15px'
    },
    inputContent: {
        display: 'flex',
        justifyContent: 'space-around',
        color: '#6b6b6b',
        alignItems: 'center',
        fontSize: 14,
        marginBottom: 5,
    },
    input: {
        color: '#6b6b6b',
        backgroundColor: 'transparent',
        border: '1px solid #e7e7e7',
        width: '94%',
        height: '34px',
        padding: '6px 12px',
        fontSize: '14px',
        borderRadius: '5px',
        marginLeft: 2
    },
    platformChek: {
        display: 'flex',
        justifyContent: 'center'
    },
    iconCheckWrap: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    btnWrap: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    textArea: {
        border: '1px solid #e7e7e7'
    }
}));

function EditCreateNotModalMemo({ open, handleClose, editData, buttonText }) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [value, setValue] = useState({})

    useEffect(() => {
        setValue(editData)
    }, [editData])
    const { active, auto, bonus, platforms, text, title, type } = value

    function add(arr = []) {
        const foundYell = arr.some(el => el.type === 'yellow');
        if (!foundYell) arr.push({ type: 'yellow', amount: 0 });
        const foundGreen = arr.some(el => el.type === 'green');
        if (!foundGreen) arr.push({ type: 'green', amount: 0 });
        return arr;
    }
    add(bonus)

    const [updateBonus, setBonus] = useState(bonus)
    const handleChange = (e, val) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value,
            bonus: bonus
        })
    }


    const handleChangeBonus = e => val => {
        const changeBonus = bonus.map(obj => obj.type === val ?
            { ...obj, amount: e.target.value } : obj)
        setBonus(changeBonus)
    }

    useEffect(() => {
        setValue({
            ...value,
            bonus: updateBonus
        })
    }, [updateBonus])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (buttonText === 'Update') {
            dispatch(updateNotificationCreate(value))
            dispatch(addUpdateNotificationCreate(value))
        } else {
            dispatch(createNotificationCreate(value))
            dispatch(addListCreateNotificationCreate(value))
        }
        handleClose()
    }

    const handleChecked = (event, value) => {
        setValue({
            ...value,
            [event.target.value]: event.target.checked
        })
    };


    return (
        <div className="modal-edit-content">
            <Modal
                id='modal-content'
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper} id="modal">
                        <div className="icon-h-wrapp">
                            <h4 className={classes.title}  >{buttonText}{' '} Notification</h4>
                            <Icon icon={closeIcon} onClick={handleClose}
                                className='iconClose' />
                        </div>
                        <form className={classes.inputWrap} onSubmit={handleSubmit} >
                            <div className={classes.inputContent}>
                                <label htmlFor="Title">Title :</label>
                                <input className={classes.input}
                                    name='title'
                                    type="text"
                                    value={title}
                                    onChange={handleChange}
                                    placeholder='title' />
                            </div>
                            <div className={classes.inputContent}>
                                <label htmlFor="Type">Type :</label>
                                <input className={classes.input}
                                    name='type'
                                    type="text"
                                    value={type}
                                    onChange={handleChange} />
                            </div>
                            <div className={classes.inputContent}
                                style={{ marginBottom: '0px', justifyContent: 'flex-start' }} >
                                <label htmlFor="Type">Active :</label>
                                <Checkbox
                                    checked={active}
                                    value='active'
                                    onChange={(e) => handleChecked(e, value)}
                                    color="default" />
                                <label htmlFor="Type">Auto :</label>
                                <Checkbox
                                    checked={auto}
                                    value='auto'
                                    onChange={(e) => handleChecked(e, value)}
                                    color="default" />
                            </div>

                            {bonus && bonus.map((el, i) => (
                                <div className={classes.inputContent} key={i}>
                                    <img style={{ width: 34 }} src={
                                        (el.type === 'red' ? redChipIcon : undefined) ||
                                        (el.type === 'yellow' ? yellowChipIcon : undefined) ||
                                        (el.type === 'green' ? greenChipIcon : undefined)
                                    } alt="icon" />
                                    <input className={classes.input}
                                        name='bonus'
                                        type="number"
                                        value={el.amount}
                                        onChange={(e) => handleChangeBonus(e)(el.type)} />
                                </div>
                            ))}
                            <div className={classes.platformChek}>
                                {platforms && platforms.map(el => (
                                    <div className={classes.iconCheckWrap} key={el}>
                                        <img style={{ width: 32 }} src={
                                            (el === 'fb' && fb) ||
                                            (el === 'vk' && vk) ||
                                            (el === 'ok' && ok) ||
                                            (el === 'ios' && ios) ||
                                            (el === 'android' && android)
                                        } alt="icon" />
                                        <Checkbox
                                            checked={true}
                                            color="default" />
                                    </div>

                                ))}
                            </div>

                            <div className={classes.inputContent}>
                                <label htmlFor="Title">Text :</label>
                                <textarea name="text" className={classes.textArea}
                                    cols="114" rows="4" onChange={handleChange}
                                    value={text} placeholder='text' />
                            </div>
                            <div className={classes.btnWrap}>
                                <button className="btn-not" onClick={handleClose}>Cancel</button>
                                <button className="btn-not" onClick={handleSubmit}>{buttonText}</button>
                            </div>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
export const EditCreateNotModal = React.memo(EditCreateNotModalMemo)

