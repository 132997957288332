import { Tooltip } from "@material-ui/core";
import "./TeamGame.scss";
import React, { useEffect, useState } from "react";
import { teamGameList_req } from "../../api/team-game/tame-game.api";
import MessageModal from "../../components/MessageModal/MessageModal";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export function TeamGame() {
    const [teamGameData, setTeamGameData] = useState([]);
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({ checked: false });
    const [isChecked, setChecked] = useState([]);
    const listTeamGame = async () => {
        try {
            const teamGameList = await teamGameList_req();
            setTeamGameData(teamGameList);
        } catch(e) {
            console.log('e', e);
        }
    };
    useEffect(() => {
        listTeamGame();
    }, []);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleCloseOpen = () => {
        setOpen(false);
    };

    const handleChecked = (e, teamId) => {
        console.log("TeamId", teamId);
        if (e.target.checked) {
            setChecked([...isChecked, teamId]);
        } else {
            isChecked.splice(isChecked.indexOf(teamId), 1);
            setChecked([...isChecked]);
        }
    };
    console.log("checkoed", isChecked);
    const renderList =
        teamGameData &&
        teamGameData.teams &&
        teamGameData.teams.map((item) => {
            return (
                <>
                    {open && (
                        <MessageModal
                            handleCloseOpen={handleCloseOpen}
                            handleOpen={handleOpen}
                            teamId={item.teamId}
                            checked={isChecked}
                        />
                    )}
                    <tr key={item._id}>
                        <Tooltip
                            title={item.teamId ? item.teamId : ""}
                            arrow
                            placement="bottom-start"
                        >
                            <td className="text">{item.teamId}</td>
                        </Tooltip>
                        <Tooltip
                            title={item.users ? item.users.length : ""}
                            arrow
                            placement="bottom-start"
                        >
                            <td className="text">{item.users.length}</td>
                        </Tooltip>
                        <Tooltip
                            title={item.leader ? item.leader : ""}
                            arrow
                            placement="bottom-start"
                        >
                            <td className="text">{item.leader}</td>
                        </Tooltip>
                        <Tooltip
                            title={item.totalBattles ? item.totalBattles : ""}
                            arrow
                            placement="bottom-start"
                        >
                            <td className="text">{item.totalBattles}</td>
                        </Tooltip>
                        <Tooltip
                            title={item.battleWon ? item.battleWon : ""}
                            arrow
                            placement="bottom-start"
                        >
                            <td className="text">{item.battleWon}</td>
                        </Tooltip>
                        <Tooltip
                            title={item.star ? item.star : ""}
                            arrow
                            placement="bottom-start"
                        >
                            <td className="text">{item.star}</td>
                        </Tooltip>
                        {/* <Tooltip
                        title={item.joinConditions ? item.joinConditions : ""}
                        arrow
                        placement="bottom-start"
                    >
                        <td className="text">{item.joinConditions.length}</td>
                    </Tooltip> */}
                        <Tooltip
                            title={
                                item.joinConditions
                                    ? item.joinConditions.length
                                    : ""
                            }
                            arrow
                            placement="bottom-start"
                        >
                            <td className="text">
                                {item.joinConditions.length}
                            </td>
                        </Tooltip>

                        <Tooltip
                            title={item.createdAt ? item.createdAt : ""}
                            arrow
                            placement="bottom-start"
                        >
                            <td className="text">
                                {new Date(item.createdAt)
                                    .toDateString()
                                    .slice(0, 10)}
                            </td>
                        </Tooltip>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isChecked.includes(item.teamId)}
                                    color="default"
                                    onChange={(e) =>
                                        handleChecked(e, item.teamId)
                                    }
                                />
                            }
                            label=""
                        />
                    </tr>
                </>
            );
        });

    return (
        <div className="team-game">
            <div onClick={() => handleOpen()} className="team-game-button">
                add
            </div>
            <div className="horizontal-scroll">
                <table className="table">
                    <thead>
                        <tr className="sortable">
                            <th>team Id(name) </th>
                            <th>Players </th>
                            <th>Lead </th>
                            <th>Played Battles </th>
                            <th>Won Battles </th>
                            <th>Stars </th>
                            <th>Condition </th>
                            <th>Creation Date </th>
                        </tr>
                    </thead>
                    <tbody>{renderList}</tbody>
                </table>
            </div>
        </div>
    );
}
