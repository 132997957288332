import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { checkLoginCreate } from "../store/actions/auth";
import { Prelouder } from "../components/Prelouder/Prelouder";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const state = useSelector((state) => state.authReducer);
  const { error, loading } = state;

  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(checkLoginCreate());
    }
  }, [dispatch]);

  if (error) {
    return <Redirect to="/sign-in" />;
  }

  return (
    <>
      {!loading ? (
        <Route
          {...rest}
          render={(props) =>
            localStorage.getItem("token") ? (
              <Component {...props} />
            ) : (
              <Redirect to="/sign-in" />
            )
          }
        />
      ) : (
        <Prelouder />
      )}
    </>
  );
};
