import React from 'react';
import { Switch, withRouter, Route } from 'react-router-dom';
import {
    Signin,
    RootPage,
    PrivateRoute
} from '../pages/index';

const Index = () => {
    return (
        <div className="main-root-container">
            <Switch>
                <Route exact path='/sign-in' component={Signin} />
                <PrivateRoute component={RootPage} path="/" />
            </Switch>
        </div>
    )
}

export default withRouter(Index)