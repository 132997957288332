import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import "./MessageModal.scss";
import Backdrop from "@material-ui/core/Backdrop";
import { Icon } from "@iconify/react";
import closeIcon from "@iconify/icons-iwwa/close";
import { teamGameMessages_req } from "../../api/team-game/tame-game.api";

const MessageModal = ({ handleOpen, handleCloseOpen, teamId, checked }) => {
    const [state, setState] = useState({ message: "" });
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: "5px",
            border: "none !important",
            boxShadow: theme.shadows[5],
            width: 900,
            padding: "0px 2px",
        },
        title: {
            color: " #7d7d7d !important",
            marginLeft: "15px",
            fontWeight: "500",
        },
        inputWrap: {
            position: "relative",
            padding: "15px",
        },
        input: {
            color: "#6b6b6b",
            backgroundColor: "transparent",
            border: "1px solid #e7e7e7",
            width: "100%",
            height: "34px",
            padding: "6px 12px",
            fontSize: "14px",
        },
    }));

    const classes = useStyles();
    const handleChange = (evt) => {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value,
        });
    };
    const handleSubmit = async () => {
        let message = state.message;
        const sendMessageData = await teamGameMessages_req(message, checked);
        if (sendMessageData === "Success") {
            handleCloseOpen();
        }
        console.log("sendmSEA", sendMessageData);
    };
    return (
        <div className="message-modal">
            {" "}
            <Modal
                id="modal-content"
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={handleOpen}
                onClose={handleCloseOpen}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={handleOpen}>
                    <div className={classes.paper} id="modal">
                        <div className="icon-h-wrapp">
                            <h4 className={classes.title}>Message</h4>
                            <Icon
                                icon={closeIcon}
                                onClick={handleCloseOpen}
                                className="iconClose"
                            />
                        </div>

                        <input
                            className={classes.input}
                            type="text"
                            name="message"
                            value={state.message}
                            onChange={handleChange}
                        />
                        <div className="btn-checkbox-wrap">
                            {/* <FormControlLabel
                                    className={classes.labelSt}
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={handleChecked}
                                            color="default"
                                        />
                                    }
                                    label="Send Notification"
                                /> */}
                            <div className="btn-checkbox-wrap"></div>
                            <button
                                id="btn"
                                onClick={() => handleSubmit(teamId)}
                            >
                                Send
                            </button>
                        </div>
                    </div>
                </Fade>
            </Modal>
             
        </div>
    );
};

export default MessageModal;
