import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import home from "@iconify/icons-oi/home";
import iosPulseStrong from "@iconify/icons-ion/ios-pulse-strong";
import statsBars from "@iconify/icons-ion/stats-bars";
import iosMailUnread from "@iconify/icons-ion/ios-mail-unread";
// import eyeIcon from '@iconify/icons-ion/eye';
// import trophyIcon from '@iconify/icons-ion/trophy';
import notificationsOutline from "@iconify/icons-ion/notifications-outline";
import { Icon } from "@iconify/react";
import { debounce } from "debounce";
import "./style.scss";

export function Menu({ isShowModal }) {
    const stateAuth = useSelector((state) => state.authReducer);
    const { role } = stateAuth;
    const [getY, setY] = useState(0);
    const [hoverRef, isHovered] = useHover();

    const handleMove = (e) => {
        setY(e.clientY);
    };
    const styles = {
        transform: `translate(${0}px, ${getY - 93}px)`,
        display: "none",
    };

    function hov() {
        if (!isHovered) {
            styles.display = "none";
        } else {
            styles.display = "block";
        }
        return styles;
    }
    let path = window.location.pathname;

    return (
        <div
            className="leftNavbar-container"
            style={isShowModal ? { width: 52 } : { width: 180 }}
        >
            <ul
                onMouseMove={(e) => debounce(handleMove(e), 700)}
                ref={hoverRef}
            >
                <div className="hover-el" style={hov()}></div>
                {(role === 3 || role === 4) && (
                    <>
                        <li className={path === "/dashboard" ? "active" : null}>
                            <Link to="/dashboard">
                                <div className="icon-wrap">
                                    <Icon height={"0.75rem"} icon={home} />
                                </div>
                                <span> Dashboard</span>
                            </Link>
                        </li>
                        <li
                            className={
                                path === "/notifications" ? "active" : null
                            }
                        >
                            <Link to="/notifications">
                                <div className="icon-wrap">
                                    <Icon
                                        width={"0.8rem"}
                                        icon={notificationsOutline}
                                    />
                                </div>
                                <span>Notifications</span>
                            </Link>
                        </li>
                        <li className={path === "/active" ? "active" : null}>
                            <Link to="/active">
                                <div className="icon-wrap">
                                    <Icon
                                        width={"0.8rem"}
                                        icon={iosPulseStrong}
                                    />
                                </div>
                                <span> Active Games</span>
                            </Link>
                        </li>
                    </>
                )}
                <li className={path === "/statistics" ? "active" : null}>
                    <Link to="/statistics">
                        <div className="icon-wrap">
                            <Icon width={"0.8rem"} icon={statsBars} />
                        </div>
                        <span>Statistics</span>
                    </Link>
                </li>
                {(role === 3 || role === 4) && (
                    <li className={path === "/usergifts" ? "active" : null}>
                        <Link to="/usergifts">
                            <div className="icon-wrap">
                                <Icon height={"0.8rem"} icon={iosMailUnread} />
                            </div>
                            <span> User Gifts</span>
                        </Link>
                    </li>
                )}
                {
                    <li className={path === "/teamGame" ? "active" : null}>
                        <Link to="/teamGame">
                            <div className="icon-wrap">
                                <Icon height={"0.8rem"} icon={iosMailUnread} />
                            </div>
                            <span> Team Game</span>
                        </Link>
                    </li>
                }
            </ul>
        </div>
    );
}

function useHover() {
    const [value, setValue] = useState(false);

    const handleMouseOver = () => setValue(true);
    const handleMouseOut = () => setValue(false);
    const ref = React.useRef(null);
    useEffect(() => {
        const node = ref.current;
        if (node) {
            node.addEventListener("mouseover", handleMouseOver);
            node.addEventListener("mouseout", handleMouseOut);

            return () => {
                node.removeEventListener("mouseover", handleMouseOver);
                node.removeEventListener("mouseout", handleMouseOut);
            };
        }
    }, []);
    return [ref, value];
}

{
    /* <li className={path === '/offers'?  'active' : null} >
<Link to='/offers'>
    <div className="icon-wrap"><Icon height={'0.8rem'} icon={eyeIcon} /></div>
    <span>Offers</span>
</Link>
</li>
<li className={path === '/tournaments'?  'active' : null} >
<Link to='/tournaments'>
    <div className="icon-wrap"><Icon height={'0.8rem'} icon={trophyIcon} /></div>
    <span>Tournaments</span>
</Link>
</li> */
}
