import {
  CHARGE_CHIP_START,
  CHARGE_CHIP_SUCCESS,
  CHARGE_CHIP__FILED,
  INCREMENT_DECREMENT,
} from "../action-types/actionTypes";
import { api } from "../../api/service";

export const chargeCreate = (data) => {
  return async (dispatch) => {
    dispatch({
      type: CHARGE_CHIP_START,
    });
    try {
      const response = await api.post("/charge", data);
      dispatch({
        type: CHARGE_CHIP_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: CHARGE_CHIP__FILED,
        error: { message: "Something went wrong." },
      });
    }
  };
};

export function changeChip(num, colorType) {
  return { type: INCREMENT_DECREMENT, payload: [num, colorType] };
}
