import {
    GET_USER_NOTES_START,
    GET_USER_NOTES_SUCCESS,
    GET_USER_NOTES_FILED,
    ADD_USER_NOTES_START,
    ADD_USER_NOTES_SUCCESS,
    ADD_USER_NOTES_FILED,
    SUCCESS_TO_FALSE,
    EDIT_USER_NOTES_START,
    EDIT_USER_NOTES_SUCCESS,
    EDIT_USER_NOTES_FILED,
    EDIT_SUCCESS_TO_FALSE
} from "../action-types/actionTypes";


const initialState = {
    loading: false,
    notes: null,
    error: null,
    addLoading: false,
    success: false,
    editLoading: false,
    editSuccess: false,
}

export function notesReducer(state = initialState, action) {
    switch (action.type) {
        case GET_USER_NOTES_START:
            return {
                ...state,
                loading: true
            }
        case GET_USER_NOTES_SUCCESS:
            return {
                ...state,
                loading: false,
                notes: action.payload
            }
        case GET_USER_NOTES_FILED:
            return {
                ...state,
                loading: false,
                error: 'samething went wrong',
            }
        case ADD_USER_NOTES_START:
            return {
                ...state,
                addLoading: true
            }
        case ADD_USER_NOTES_SUCCESS:
            return {
                ...state,
                addLoading: false,
                success: action.payload.success
            }
        case ADD_USER_NOTES_FILED:
            return {
                ...state,
                addLoading: false,
                error: 'samething went wrong',
            }
        case SUCCESS_TO_FALSE:
            return {
                ...state,
                success: false
            }
        case EDIT_USER_NOTES_START:
            return {
                ...state,
                editLoading: true,
            }
        case EDIT_USER_NOTES_SUCCESS:
            return {
                ...state,
                addLoading: false,
                editSuccess: action.payload.success
            }
        case EDIT_USER_NOTES_FILED:
            return {
                ...state,
                addLoading: false,
                error: 'samething went wrong',
            }
        case EDIT_SUCCESS_TO_FALSE:
            return {
                ...state,
                editSuccess: false
            }
        default:
            return state;
    }
}