
import {
    GET_VERSION_START,
    GET_VERSION_SUCCESS,
    GET_VERSION_FILED,
} from "../action-types/actionTypes";


const initialState = {
    loading: false,
    version: null,
    error: null,
  }

export function getVersionReducer(state = initialState, action) {
    switch (action.type) {
        case GET_VERSION_START:
            return {
                ...state,
                loading: true
            }
        case GET_VERSION_SUCCESS:
            return {
                ...state,
                loading: false,
                version: action.payload
            }
        case GET_VERSION_FILED:
            return {
                ...state,
                loading: false,
                error: 'samething went wrong',
            }
        default:
            return state;
    }
}