import React from 'react'
import CheckIcon from '@material-ui/icons/Check';
import './style.scss'

export function HideShowSeries({check, handleChangeSeries, backgroundColor}) {

    return (
        <button className='btn-check' style={!check? {background: backgroundColor}: null}  
        onClick={handleChangeSeries} >
            <CheckIcon fontSize={'small'} style={!check? {color: 'white'}: null}  />
        </button>
    )
}
