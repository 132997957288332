export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FILED = 'AUTH_FILED';

export const SIGN_OUT_START = 'SIGN_OUT_START'
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS'
export const SIGN_OUT_FILED = 'SIGN_OUT_FILED'

export const SEARCH_START_USER = 'SEARCH_START_USER'
export const SEARCH_SUCCESS_USER = 'SEARCH_SUCCESS_USER'
export const SEARCH_FILED_USER = 'SEARCH_FILED_USER'

export const CHECK_LOGIN_START = 'CHECK_LOGIN_START'
export const CHECK_LOGIN_SUCCESS = 'CHECK_LOGIN_SUCCESS'
export const CHECK_LOGIN_FILED = 'CHECK_LOGIN_FILED'

export const GET_USER_START = 'GET_USER_START'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER__FILED = 'GET_USER__FILED'

export const CHARGE_CHIP_START = 'CHARGE_CHIP_START'
export const CHARGE_CHIP_SUCCESS = 'CHARGE_CHIP_SUCCESS'
export const CHARGE_CHIP__FILED = 'CHARGE_CHIP_FILED'
export const INCREMENT_DECREMENT = 'INCREMENT_DECREMENT'

export const GET_VERSION_START = 'GET_VERSION_START'
export const GET_VERSION_SUCCESS = 'GET_VERSION_SUCCESS'
export const GET_VERSION_FILED = 'GET_VERSION_FILED'

export const GET_USER_TRANSITION_START = 'GET_USER_TRANSITION_START'
export const GET_USER_TRANSITION_SUCCESS = 'GET_USER_TRANSITION_SUCCESS'
export const GET_USER__TRANSITION_FILED = 'GET_USER__TRANSITION_FILED'

export const GET_GAME_DETALIS_START = 'GET_GAME_DETALIS_START'
export const GET_GAME_DETALIS_SUCCESS = 'GET_GAME_DETALIS_SUCCESS'
export const GET_GAME_DETALIS_FILED = 'GET_GAME_DETALIS_FILED'

export const FINISHED_GAME_START = 'FINISHED_GAME_START'
export const FINISHED_GAME_SUCCESS = 'FINISHED_GAME_SUCCESS'
export const FINISHED_GAME_FILED = 'FINISHED_GAME_FILED'

export const NOTIFICATION_START = 'NOTIFICATION_START'
export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS'
export const NOTIFICATION_FILED = 'NOTIFICATION_FILED'

export const UPDATE_NOT_START = 'UPDATE_NOT_START'
export const UPDATE_NOT_SUCCESS = 'UPDATE_NOT_SUCCESS'
export const UPDATE_NOT_FILED = 'UPDATE_NOT_FILED'

export const CREATE_NOT_START = 'CREATE_NOT_START'
export const CREATE_NOT_SUCCESS = 'CREATE_NOT_SUCCESS'
export const CREATE_NOT_FILED = 'CREATE_NOT_FILED'

export const DELETE_NOT_START = 'DELETE_NOT_START'
export const DELETE_NOT_SUCCESS = 'DELETE_NOT_SUCCESS'
export const DELETE_NOT_FILED = 'DELETE_NOT_FILED'

export const CREATE_NOT_LIST = 'CREATE_NOT_LIST'
export const DELETE_NOT_LIST = 'DELETE_NOT_LIST'
export const UPDATE_NOT_LIST = 'UPDATE_NOT_LIST'

export const SEND_NOT_START = 'SEND_NOT_START'
export const SEND_NOT_SUCCESS = 'SEND_NOT_SUCCESS'
export const SEND_NOT_FILED = 'SEND_NOT_FILED'

export const GET_ACTIVE_GAME_START = 'GET_ACTIVE_GAME_START'
export const GET_ACTIVE_GAME_SUCCESS = 'GET_ACTIVE_GAME_SUCCESS'
export const GET_ACTIVE_GAME_FILED = 'GET_ACTIVE_GAME_FILED'

export const DISABLE_GAME_START = 'DISABLE_GAME_START'
export const DISABLE_GAME_SUCCESS = 'DISABLE_GAME_SUCCESS'
export const DISABLE_GAME_FILED = 'DISABLE_GAME_FILED'

export const CHARGE_BACK_START = 'CHARGE_BACK_START'
export const CHARGE_BACK_SUCCESS = 'CHARGE_BACK_SUCCESS'
export const CHARGE_BACK_FILED = 'CHARGE_BACK_FILED'

export const PURCHASES_START = 'PURCHASES_START'
export const PURCHASES_SUCCESS = 'PURCHASES_SUCCESS'
export const PURCHASES_FILED = 'PURCHASES_FILED'

export const GAMES_START = 'GAMES_START'
export const GAMES_SUCCESS = 'GAMES_SUCCESS'
export const GAMES_FILED = 'GAMES_FILED'

export const GET_GIFT_CARD_START = 'GET_GIFT_CARD_START'
export const GET_GIFT_CARD_SUCCESS = 'GET_GIFT_CARD_SUCCESS'
export const GET_GIFT_CARD_FILED =  'GET_GIFT_CARD_FILED'

export const UPDATE_GIFT_CARD_START = 'UPDATE_GIFT_CARD_START'
export const UPDATE_GIFT_CARD_SUCCESS = 'UPDATE_GIFT_CARD_SUCCESS'
export const UPDATE_GIFT_CARD_FILED =  'UPDATE_GIFT_CARD_FILED'

export const UPDATE_GIFT_CARD_TO_FALSE = 'UPDATE_GIFT_CARD_TO_FALSE'

export const GET_USER_NOTES_START = 'GET_USER_NOTES_START'
export const GET_USER_NOTES_SUCCESS = 'GET_USER_NOTES_SUCCESS'
export const GET_USER_NOTES_FILED = 'GET_USER_NOTES_FILED'

export const ADD_USER_NOTES_START = 'ADD_USER_NOTES_START'
export const ADD_USER_NOTES_SUCCESS = 'ADD_USER_NOTES_SUCCESS'
export const ADD_USER_NOTES_FILED = 'ADD_USER_NOTES_FILED'

export const EDIT_USER_NOTES_START = 'EDIT_USER_NOTES_START'
export const EDIT_USER_NOTES_SUCCESS = 'EDIT_USER_NOTES_SUCCESS'
export const EDIT_USER_NOTES_FILED = 'EDIT_USER_NOTES_FILED'

export const SUCCESS_TO_FALSE = 'SUCCESS_TO_FALSE'
export const EDIT_SUCCESS_TO_FALSE = 'EDIT_SUCCESS_TO_FALSE'

export const GET_TOUR_NAMENT_START = 'GET_TOUR_NAMENT_START'
export const GET_TOUR_NAMENT_SUCCESS = 'GET_TOUR_NAMENT_SUCCESS'
export const GET_TOUR_NAMENT_FILED = 'GET_TOUR_NAMENT_FILED'

export const NEW_USERS_START = 'NEW_USERS_START'
export const NEW_USERS_SUCCESS = 'NEW_USERS_SUCCESS'
export const NEW_USERS_FILED = 'NEW_USERS_FILED'