import axios from "axios";
const baseURL =
    process.env.REACT_APP_BUILD_ENV !== "production"
        ? "https://test.blotclub.am/api/admin"
        : "https://vk.blotclub.am/api/admin";

const TeamURL =
    process.env.REACT_APP_BUILD_ENV !== "production"
        ? "https://test.blotclub.am/tmapi/admin"
        : "https://vk.blotclub.am/api/admin";

export const api = axios.create({
    timeout: 60000,
    baseURL: baseURL,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    },
});

export const axiosInstanceTeam = axios.create({
    baseURL: TeamURL,
    timeout: 60000,
    // withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    },
});

const successResponse = (response) => {
    return response;
};

const errorResponse = (error) => {
    return Promise.reject(error);
};

api.interceptors.response.use(
    function (response) {
        if (response.data.status === "200") {
        }
        return response;
    },
    function (error) {
        console.log("here", error);
        if (!error.response) {
            return Promise.reject({
                response: {
                    status: 500,
                    message: "Something went wrong.",
                },
            });
        } else if (error.response.status === 401) {
            console.log("error 401", error);
            localStorage.removeItem("token");
            window.location = "/sign-in";
            return Promise.reject(error);
        } else {
            return Promise.reject(error);
        }
    }
);
const setTeamHeaders = (reqConfig) => {
    reqConfig.headers["secret"] = "eea502aa-edad-4528-bebc-3f3ba8d5e49f";
    // if (userSingleton.jwt) {
    //     reqConfig.headers.jwt = userSingleton.jwt;
    // }
    return reqConfig;
};

axiosInstanceTeam.interceptors.response.use(successResponse, errorResponse);
axiosInstanceTeam.interceptors.request.use(setTeamHeaders);
