import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getActiveGamesCreate } from '../../store/actions/getGameDetalis'
import { getDays } from '../../components/Methods/Methods'
import Checkbox from '@material-ui/core/Checkbox';
import { Pagination } from '../../components/Pagination/Pagination'
import { money } from '../../assets/'
import CachedIcon from '@material-ui/icons/Cached';
import { ModalAreYouSure } from './ModalAreYouSure'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import './style.scss'

export function ActiveGames() {
    const stateAuth = useSelector(state => state.authReducer)
    const { role } = stateAuth
    const dispatch = useDispatch()
    useEffect(() => {
        const path = window.location.pathname.slice(1)
        if (path === 'active') {
            dispatch(getActiveGamesCreate())
        }
    }, [])
    const state = useSelector(state => state.activeGameReduser)
    const chargeState = useSelector(state => state.chargeBackReduser)
    const { status } = chargeState
    const copyState = { ...state }
    const { activeGame } = copyState



    const [todos, setToto] = useState(activeGame)
    const [currentPage, setCurrentPage] = useState(1)
    const [selectPage, setSelectPage] = useState(0)
    // const [selected, setSelected] = useState(false);
    // const [isSortScore, setSortScore] = useState(false)
    const [isSortRoomType, setSortRoomType] = useState(false)
    const [isSortGameId, setSortGameId] = useState(false)
    const [isSortStarts, setSortStarts] = useState(false)

    useEffect(() => {
        activeGame && activeGame.sort((a, b) => new Date(a.started) < new Date(b.started) ? 1 : -1)
        setToto(activeGame)
    }, [activeGame])

    useEffect(() => {
        if (status) dispatch(getActiveGamesCreate())
    }, [status])

    const handleSortRoomType = () => {
        setSortRoomType(!isSortRoomType)
        if (isSortRoomType) {
            todos.sort((a, b) => a.roomType.toLowerCase() > b.roomType.toLowerCase() ? 1 : -1)
        } else {
            todos.sort((a, b) => a.roomType.toLowerCase() < b.roomType.toLowerCase() ? 1 : -1)
        }
    }
    const handleSortGameId = () => {
        setSortGameId(!isSortGameId)
        if (isSortGameId) {
            todos.sort((a, b) => a.gameId.toLowerCase() > b.gameId.toLowerCase() ? 1 : -1)
        } else {
            todos.sort((a, b) => a.gameId.toLowerCase() < b.gameId.toLowerCase() ? 1 : -1)
        }
    }

    const handleSortStarts = () => {
        setSortStarts(!isSortStarts)
        if (isSortStarts) {
            todos.sort((a, b) => new Date(a.started) < new Date(b.started) ? 1 : -1)
        } else {
            todos.sort((a, b) => new Date(a.started) > new Date(b.started) ? 1 : -1)
        }
    }



    //pagination
    const indexOfLastTodo = currentPage * 15;
    const indexOfFirstTodo = indexOfLastTodo - 15;
    let pageCount = 0;

    if (todos && todos.length) {
        pageCount = Math.ceil(todos.length / 15)
    }


    let currentTodos = []
    if (todos) {
        currentTodos = todos.slice(indexOfFirstTodo, indexOfLastTodo);
    }



    const onChangePage = i => {
        setSelectPage(i.selected)
        setCurrentPage(i.selected + 1)
    }
    const [isChecked, setChecked] = useState([])
    const handleChecked = (e, id) => {
        if (e.target.checked) {
            setChecked([...isChecked, id])
        } else {
            isChecked.splice(isChecked.indexOf(id), 1)
            setChecked([...isChecked])
        }
    };

    let selectAllArr = currentTodos.map(el => el._id)
    const handleCheckedAll = (e) => {
        if (e.target.checked) {
            setChecked(selectAllArr)
        } else {
            setChecked([])
        }
    }
    const filterDataForCharge = currentTodos.filter(el => isChecked.includes(el._id))
    const dataForCharge = filterDataForCharge.map(el => el ? { ...el, chargeBack: true } : null)




    const renderList = currentTodos.map((row, index) => {
        return <tr key={index}>
            <td><Checkbox
                checked={isChecked.includes(row._id)}
                size={'small'}
                onChange={(e) => handleChecked(e, row._id)}
                color="default" /></td>
            <td>{row.gameId}</td>
            <td><span>{getDays(row.started)}</span></td>
            <td>{row.roomType}</td>
            <td>{row.users.map(el => (
                <span key={el.userId} >{el.userId + ', '}</span>
            ))}</td>
        </tr>;
    });

    const pageNumbers = [];
    if (todos) {
        for (let i = 1; i <= Math.ceil(todos.length / pageCount); i++) {
            pageNumbers.push(i);
        }
    }


    const handleRefresh = () => {
        dispatch(getActiveGamesCreate())
    }
    //props for modal are you sure 
    const [openAreYouSure, setOpenAreYouSure] = useState(false);

    const handleOpenAreYouSure = (id) => {
        setOpenAreYouSure(true)
    }
    const handleCloseAreYouSure = () => {
        setOpenAreYouSure(false)
    }



    return (
        <> {role === 2 ? null : <><div className='panel profile-page animated zoomIn' style={{ width: 'fit-content' }} >
         </div>
        <ModalAreYouSure
                    dataForCharge={dataForCharge}
                    openAreYouSure={openAreYouSure}
                    handleCloseAreYouSure={handleCloseAreYouSure} />
            <div className="ActiveGames-container">
                <div className='panel profile-page animated zoomIn'>
                    <div className="panel-body">
                        <div className="body-content">
                            <div className="header-content">
                                <h3 className="header">Active Games {activeGame && activeGame.length}</h3>
                                <button className='btn'  >
                                    <CachedIcon onClick={handleRefresh} fontSize={'small'} style={{ color: 'white' }} />
                                </button>
                                <button className='btn' onClick={handleOpenAreYouSure} >
                                    <img src={money} style={{ width: 18, margin: '1px 2px' }} alt="money" />
                                </button>
                            </div>
                            <div className="horizontal-scroll">
                                <table className="table">
                                    <thead>
                                        <tr className="sortable">
                                            <th>Select <span>
                                                <Checkbox
                                                    checked={isChecked.toString() === selectAllArr.toString()}
                                                    size={'small'}
                                                    onChange={(e) => handleCheckedAll(e)}
                                                    color="default" />
                                            </span>
                                            </th>
                                            <th onClick={handleSortGameId}>Game Id {!isSortGameId ?
                                                <ArrowDropDownIcon style={{ verticalAlign: 'middle' }} fontSize={'small'} /> :
                                                <ArrowDropUpIcon style={{ verticalAlign: 'middle' }} fontSize={'small'} />}
                                            </th>
                                            <th onClick={handleSortStarts}>Start {!isSortStarts ?
                                                <ArrowDropDownIcon style={{ verticalAlign: 'middle' }} fontSize={'small'} /> :
                                                <ArrowDropUpIcon style={{ verticalAlign: 'middle' }} fontSize={'small'} />}
                                            </th>
                                            <th onClick={handleSortRoomType}>Room Type{!isSortRoomType ?
                                                <ArrowDropDownIcon style={{ verticalAlign: 'middle' }} fontSize={'small'} /> :
                                                <ArrowDropUpIcon style={{ verticalAlign: 'middle' }} fontSize={'small'} />}
                                            </th>
                                            <th>Users</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderList}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="6" className="text-center" >
                                                <div className="nav">
                                                    {pageCount > 1 && <Pagination
                                                        selectPage={selectPage}
                                                        pageCount={pageCount}
                                                        onChangePage={(i) => onChangePage(i)}
                                                    />}
                                                </div>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>}
        </>
    )
}

