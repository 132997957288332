import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signinCreate } from "../../store/actions/auth";
import { Redirect } from "react-router-dom";
import { Prelouder } from "../../components/Prelouder/Prelouder";
import "./style.scss";

export function Signin() {
  const [value, setValues] = useState({ username: "", password: "" });

  const dispatch = useDispatch();

  const state = useSelector((state) => state.authReducer);
  const { role } = state;
  const { error } = state;

  const handleChange = (e) => {
    setValues({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValues(value);
    dispatch(signinCreate(value));
  };

  if (role === 2 && localStorage.getItem("token")) {
    return <Redirect to="/statistics" />;
  }
  if (localStorage.getItem("token")) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className="sign-in-container">
      {role ? (
        <Prelouder />
      ) : (
        <div className="sign-in-content">
          <form action="" onSubmit={handleSubmit}>
            <h1>BlotClub Admin</h1>
            {error && <div className="alert">{error}</div>}
            <div className="row-wrapp">
              <label htmlFor="username">Username</label>
              <input
                name="username"
                type="name"
                id="email"
                value={value.username}
                onChange={handleChange}
                placeholder="Username"
                required
              />
            </div>
            <div className="row-wrapp">
              <label htmlFor="password">Password</label>
              <input
                name="password"
                type="password"
                id="password"
                value={value.password}
                onChange={handleChange}
                placeholder="Password"
                required
              />
            </div>
            <div className="btn-wrapper">
              <button>Sign in</button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
