import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Icon, } from '@iconify/react';
import closeIcon from '@iconify/icons-iwwa/close';

import './style.scss';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '5px',
        border: 'none !important',
        boxShadow: theme.shadows[5],
        width: 900,
        padding: '0px 2px'

    },
    title: {
        color: ' #7d7d7d !important',
        marginLeft: '15px',
        fontWeight: '500',
    },
    inputWrap: {
        position: 'relative',
        padding: '15px'
    },
    input: {
        color: '#6b6b6b',
        backgroundColor: 'transparent',
        border: '1px solid #e7e7e7',
        width: '100%',
        height: '34px',
        padding: '6px 12px',
        fontSize: '14px'
    },
}));

function AddModalMemo({ value, handleSubmit,
    handleChange, open, handleClose, checked, handleChecked }) {
    const classes = useStyles();

    return (
        <div className="modal-add-content">
            <Modal
                id='modal-content'
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper} id="modal">
                        <div className="icon-h-wrapp">
                            <h4 className={classes.title}  >Add Chip</h4>
                            <Icon icon={closeIcon} onClick={handleClose}
                                className='iconClose' />
                        </div>
                        <form className={classes.inputWrap} onSubmit={handleSubmit}  >
                            <input className={classes.input}
                                name='amount'
                                type="number"
                                value={value}
                                onChange={handleChange} />
                            <div className="btn-checkbox-wrap">
                                <FormControlLabel className={classes.labelSt}
                                    control={<Checkbox
                                        checked={checked}
                                        onChange={handleChecked}
                                        color="default" />}
                                    label="Send Notification" />
                                <button id="btn" onClick={handleSubmit}>Add</button>
                            </div>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
export const AddModal = React.memo(AddModalMemo);