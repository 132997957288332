
import {
    GET_GIFT_CARD_START,
    GET_GIFT_CARD_SUCCESS,
    GET_GIFT_CARD_FILED,
    UPDATE_GIFT_CARD_START,
    UPDATE_GIFT_CARD_SUCCESS,
    UPDATE_GIFT_CARD_FILED,
    UPDATE_GIFT_CARD_TO_FALSE
} from "../action-types/actionTypes";


const initialState = {
    loading: false,
    giftCardsOrders: null,
    error: null,
    isUpdategiftCards: false,
    updateError: null,
    updateLoading: false,
  }

export function getGiftCardsOrdersReducer(state = initialState, action) {
    switch (action.type) {
        case GET_GIFT_CARD_START:
            return {
                ...state,
                loading: true
            }
        case GET_GIFT_CARD_SUCCESS:
            return {
                ...state,
                loading: false,
                giftCardsOrders: action.payload
            }
        case GET_GIFT_CARD_FILED:
            return {
                ...state,
                loading: false,
                error: 'samething went wrong',
            }
            case UPDATE_GIFT_CARD_START:
                return {
                    ...state,
                    updateLoading: true
                }
            case UPDATE_GIFT_CARD_SUCCESS:
                return {
                    ...state,
                    updateLoading: false,
                    isUpdategiftCards: action.payload.status
                }
            case UPDATE_GIFT_CARD_FILED:
                return {
                    ...state,
                    updateLoading: false,
                    updateError: 'samething went wrong',
                }
                case UPDATE_GIFT_CARD_TO_FALSE:
                    return {
                        ...state,
                        isUpdategiftCards: false,
                     }
            
        default:
            return state;
    }
}