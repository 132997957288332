import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    getGiftCardsOrdersCreate,
    updateGiftCardsOrderCreate,
    changeToFalseisUpdategiftCards
} from '../../store/actions/getGiftCardsOrders';
import { Table } from '../../components/Table/Table';
import { TextFields } from '../../components/TextField/TextField';
import { EditButton } from '../../components/EditButton/EditButton';
import { Button } from '../../components/Button/Button';
import { NativeSelects } from '../../components/Select/Select';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { UserGiftDetalis } from './UserGiftDetalis';
import { createColorFromStatus } from '../../components/Methods/Methods';
import './style.scss';

export function UserGifts() {
    const dispatch = useDispatch();
    const stateGiftCards = useSelector(state => state.getGiftCardsOrdersReducer);
    const copyState = { ...stateGiftCards };
    const { giftCardsOrders, isUpdategiftCards } = copyState;

    const [todos, setTodos] = useState([]);
    const thArr = ['', 'User ID', 'Order ID', 'Username', 'Tracking number', 'Status', ''];

    useEffect(() => {
        dispatch(getGiftCardsOrdersCreate());
    }, [dispatch]);

    useEffect(() => {
        if (giftCardsOrders) {
            giftCardsOrders.sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1);
            setTodos(giftCardsOrders);
        }
    }, [giftCardsOrders]);

    useEffect(() => {
        if (isUpdategiftCards) {
            alert('Data has been Updated!');
            dispatch(getGiftCardsOrdersCreate());
            dispatch(changeToFalseisUpdategiftCards(false));
        }
    }, [dispatch, isUpdategiftCards]);


    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [selectPage, setSelectPage] = useState(0);
    const [searchId, setSearchId] = useState(0);
    const indexOfLastTodo = currentPage * 15;
    const indexOfFirstTodo = indexOfLastTodo - 15;
    let pageCount = 0;

    if (todos && todos.length) {
        pageCount = Math.ceil(todos.length / 15);
    }
    const onChangePage = i => {
        setSelectPage(i.selected);
        setCurrentPage(i.selected + 1);
    };

    const handleChange = e => {
        setSearchId(e.target.value);
    };

    const filterUserId = giftCardsOrders && giftCardsOrders.filter(el => searchId.length > 0 && el.userId.toString().slice(0, searchId.length).includes(searchId));

    useEffect(() => {
        if (filterUserId && filterUserId.length > 0) {
            setTodos(filterUserId);
        } else {
            setTodos(giftCardsOrders);
        }
    }, [searchId]);

    let currentTodos = [];
    if (todos) {
        currentTodos = todos.slice(indexOfFirstTodo, indexOfLastTodo);
    }
    const pageNumbers = [];
    if (todos) {
        for (let i = 1; i <= Math.ceil(todos.length / pageCount); i++) {
            pageNumbers.push(i);
        }
    }

    const [edit, setEdit] = useState([]);
    const [data, setData] = useState({});

    const handleEdit = (obj) => {
        edit.push(obj.orderId);
        setEdit([...edit]);
        setData(obj);
    };
    const handleCancel = (obj) => {
        edit.splice(edit.indexOf(obj.orderId), 1);
        setEdit([...edit]);
    };

    const handleChangeEdit = (e, row) => {
        setData({
            ...data,
            trackingNumber: e.target.value
        });

    };

    const handleUpdate = (obj) => {
        dispatch(updateGiftCardsOrderCreate(data));
        edit.splice(edit.indexOf(obj.orderId), 1);
        setEdit([...edit]);
    };

    //editStatus -------------------------------
    const [selectVal, setSelectVal] = useState({ value: null, orderId: null });
    const [checkStatus, setCheckStatus] = useState([]);
    const handleChangeSelect = (e, row) => {
        checkStatus.push(row.orderId);
        setCheckStatus([...checkStatus]);
        setSelectVal({
            ...selectVal,
            value: e.target.value,
            orderId: row.orderId
        });

        setData({
            ...row,
            status: e.target.value
        });

    };
    const handleUpdateStatus = (orderId) => {

        dispatch(updateGiftCardsOrderCreate(data));
    };
    //showDetalis -------------------------------
    const [isShowDetalis, setShowDetalis] = useState([]);
    const handleClickShow = orderId => {
        if (!isShowDetalis.includes(orderId)) {
            setShowDetalis([...isShowDetalis, orderId]);
        } else {
            isShowDetalis.splice(isShowDetalis.indexOf(orderId), 1);
            setShowDetalis([...isShowDetalis]);
        }
    };

    const renderList = currentTodos.map((row, index) => {
        return <Fragment key={row.id}>
            <tr key={index}>
                <td>
                    <button className='plus' onClick={(e) => handleClickShow(row.orderId)}>
                        {isShowDetalis.includes(row.orderId) ?
                            <RemoveIcon fontSize='small' htmlColor='white' /> :
                            <AddIcon fontSize='small' htmlColor='white' />}
                    </button></td>
                <td>{row.userId}</td>
                <td>{row.orderId}</td>
                <td>{row.fullName ? row.fullName : 'undefaned'}</td>
                <td style={edit.includes(row.orderId) ? { display: 'block' } : { display: 'flex' }} >
                    {!edit.includes(row.orderId) &&
                        <EditButton handleEdit={(data) => handleEdit(row)} />}
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        {edit.includes(row.orderId) &&
                            <TextFields style={isUpdategiftCards ? { display: 'none' } : { display: 'block' }}
                                width='150px'
                                label={'track-number'}
                                value={data.trackingNumber ? data.trackingNumber : ''}
                                handleChange={(e) => handleChangeEdit(e, row)} />}
                        <span style={{ marginLeft: 6 }}> {row.trackingNumber}</span>
                    </span>
                    <span> </span>
                    {edit.includes(row.orderId) &&
                        <><Button handleClick={(data) => handleUpdate(row)} text='update' ></Button>
                            <Button handleClick={(data) => handleCancel(row)} text='cancel' ></Button>
                        </>} </td>
                <td>
                    <NativeSelects createColorFromStatus={createColorFromStatus(row.status)}
                        value={(checkStatus.includes(row.orderId) && row.orderId === selectVal.orderId) ?
                            (selectVal.value != null ? selectVal.value : row.status) :
                            row.status}
                        handleChange={(e) => handleChangeSelect(e, row)}
                    />
                </td>
                <td> <Button className='userGift-btn' text='update' handleClick={() => handleUpdateStatus()} /></td>
            </tr>
            {true && isShowDetalis.includes(row.orderId) &&
                <tr><td colSpan='7' style={{ padding: 0 }} >
                    <UserGiftDetalis
                        giftDetalis={row}
                        id={row.orderId}
                    /></td></tr>}</Fragment>;
    });

    return (
        <> <div className='panel profile-page animated zoomIn' style={{ width: 'fit-content' }} >
            <div className="panel-body">
                <h3 style={{ color: '#666666', fontWeight: 400, margin: '0 0 5px 0' }}>Search</h3>
                <TextFields
                    value={searchId ? searchId : ''}
                    width='150px'
                    label={'userId'}
                    handleChange={handleChange} />
            </div>
        </div>
            <div className='userGifts-container'>

                <div className='panel profile-page animated zoomIn resposive' >
                    <div className="panel-body">
                        <div className="body-content">
                            <div className="horizontal-scroll">
                                <Table
                                    value={searchId}
                                    thArr={thArr}
                                    pageCount={pageCount}
                                    selectPage={selectPage}
                                    onChangePage={onChangePage}
                                    renderList={renderList} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


