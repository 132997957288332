import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { RadioComp } from '../Radio/Radio'
import { vk, ok, fb, ios, android } from '../../assets'
import { sendNotificationCreate } from '../../store/actions/notification'
import { Icon, } from '@iconify/react';
import closeIcon from '@iconify/icons-iwwa/close';
import { MaterialUIPickers } from '../DatePicker/DatePicker'
import './style.scss'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '5px',
        border: 'none !important',
        boxShadow: theme.shadows[5],
        width: 900,
        padding: '0px 2px'
    },
    title: {
        color: ' #7d7d7d !important',
        marginLeft: '15px',
        fontWeight: '500',
    },
    inputWrap: {
        position: 'relative',
        padding: '15px'
    },
    inputContent: {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#6b6b6b',
        alignItems: 'center',
        fontSize: 14,
        marginBottom: 10,
    },
    input: {
        color: '#6b6b6b',
        backgroundColor: 'transparent',
        border: '1px solid #e7e7e7',
        width: '100%',
        height: '34px',
        padding: '6px 12px',
        fontSize: '14px',
        borderRadius: '5px',
        marginLeft: 2
    },
    platformChek: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 15
    },
    iconCheckWrap: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: 8
    },
    btnWrap: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    radioContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#6b6b6b',
        fontSize: 14,
        fontWeight: 400
    }

}));

function SendModalMemo({ openSendModal, handleClose, sendModalData, handleCloseSendModal }) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [value, setValue] = useState({})
    
    useEffect(() => {
        setValue(sendModalData)
    }, [sendModalData])
    const { platforms } = value

    const [selectedValue, setSelectedValue] = useState('passive-users')
    const [endDate, setEndDate] = useState(new Date())
    const d = new Date()
    const [startDate, setStartDate] = useState(new Date(d.setDate(d.getDate()-45)))
    const [uid, setUid] = useState(null)


    const handleDateChange = (date, type) => {
        if (type === 'from') setStartDate(date)
        if (type === 'to') setEndDate(date)
    };

    const handleChange = (value) => {
        setSelectedValue(value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(sendNotificationCreate({
            endDate: endDate,
            notId: 34,
            startDate: startDate,
            users: "passiveUsers"
        }))
        handleCloseSendModal()
    }
    const handleChangeUid = (e) => {
        setUid(e.target.value)
    }
    const styleRadeo = { marginRight: '-7px' }
    //All Users Passive Users Specific Users
    return (
        <div className="modal-edit-content">
            <Modal
                id='modal-content'
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openSendModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openSendModal}>
                    <div className={classes.paper} id="modal">
                        <div className="icon-h-wrapp">
                            <h4 className={classes.title}  >Send Notification</h4>
                            <Icon icon={closeIcon} onClick={handleCloseSendModal}
                                className='iconClose' />
                        </div>
                        <form className={classes.inputWrap} onSubmit={handleSubmit} >
                            <div className={classes.radioContent}>
                                <label htmlFor="title" style={{ marginRight: 6 }} >
                                    <RadioComp
                                        styleRadeo={styleRadeo}
                                        handleChange={(e) => handleChange('all-users')}
                                        checked={selectedValue === 'all-users' ? true : false}
                                    /> All Users</label>

                                <label htmlFor="title" style={{ marginRight: 6 }} >
                                    <RadioComp
                                        styleRadeo={styleRadeo}
                                        handleChange={(e) => handleChange('passive-users')}
                                        checked={selectedValue === 'passive-users' ? true : false}
                                    />Passive Users</label>

                                <label htmlFor="title" style={{ marginRight: 6 }} >
                                    <RadioComp
                                        styleRadeo={styleRadeo}
                                        handleChange={(e) => handleChange('specific-users')}
                                        checked={selectedValue === 'specific-users' ? true : false}
                                    />Specific Users</label>
                            </div>
                            <div className={classes.platformChek}>
                                {platforms && platforms.map(el => (
                                    <div className={classes.iconCheckWrap} key={el}>
                                        <img style={{ width: 32 }} src={
                                            (el === 'fb' && fb) ||
                                            (el === 'vk' && vk) ||
                                            (el === 'ok' && ok) ||
                                            (el === 'ios' && ios) ||
                                            (el === 'android' && android)
                                        } alt="icon" />
                                    </div>))}
                            </div>


                            {selectedValue === 'specific-users' &&
                                <div className={classes.inputContent}>
                                    <input className={classes.input}
                                        name='title'
                                        type="text"
                                        value={uid}
                                        onChange={handleChangeUid}
                                        placeholder='uid1, uid2, uid3' />
                                </div>}
                            {selectedValue === 'passive-users' ? <> <div className={classes.inputContent}>
                                <label htmlFor="Title">From :</label>
                                <MaterialUIPickers
                                    width={'94%'}
                                    selectedDate={startDate}
                                    handleDateChange={(e) => handleDateChange(e, 'from')} />
                            </div>
                                <div className={classes.inputContent}>
                                    <label htmlFor="Type">To :</label>
                                    <MaterialUIPickers
                                        width={'94%'}
                                        selectedDate={endDate}
                                        handleDateChange={(e) => handleDateChange(e, 'to')} />
                                </div></> : null}
                            <div className={classes.btnWrap}>
                                <button className="btn-not" onClick={handleCloseSendModal}>Cancel</button>
                                <button className="btn-not" onClick={handleSubmit}>Send</button>
                            </div>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
export const SendModal = React.memo(SendModalMemo)

