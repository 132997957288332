import { combineReducers } from 'redux';
import { authReducer } from '../store/reducer/authReducer';
import { userSearch } from './reducer/searchReducer'
import { getUserData } from './reducer/getUserReduser'
import { transitionPurchaseReducer } from './reducer/TransitionPurchaseReducer'
import { gameDetalisReduser} from './reducer/gameDetalisReduser'
import { getVersionReducer } from './reducer/getVersionReduser'
import { finishedGameReducer } from './reducer/finishedGameReduser'
import { notificationReducer } from './reducer/notificationReduser'
import { activeGameReduser } from './reducer/activeGameReducer'
import { chargeBackReduser } from './reducer/chargeBackReducer'
import { gameReduser } from './reducer/gamesReducer'
import { purchaseReducer } from './reducer/purchaseReducer'
import { getGiftCardsOrdersReducer }  from './reducer/getGiftCardsReducer'
import { notesReducer } from './reducer/notesReducer'
import { tournamentReducer } from './reducer/tournamentReducer'
import { newUsersReducer } from './reducer/newUsersReducer'
export default combineReducers({
    authReducer,
    userSearch,
    getUserData,
    transitionPurchaseReducer,
    gameDetalisReduser,
    getVersionReducer,
    finishedGameReducer,
    notificationReducer,
    activeGameReduser,
    chargeBackReduser,
    gameReduser,
    purchaseReducer,
    getGiftCardsOrdersReducer,
    notesReducer,
    tournamentReducer,
    newUsersReducer
})