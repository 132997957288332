import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { powerOff } from '../../assets'
import { useDispatch, } from 'react-redux';
import { signoutCreate } from '../../store/actions/auth'
import './style.scss'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        fontSize: '0.85rem !important'

    },
    paper: {
        marginRight: theme.spacing(2),
    },
    icon: {
        width: 13,
        marginRight: 10,
        height: ' 27px !important'
    },
    menuitem: {
        padding: '0px 60px 0px 18px',
        minHeight: 35
    },
    conent: {
        top: ' 22px !important',
        left: '-28px !important',
        position: 'relative',
        ['@media (max-width:769px)']: {
            left: '0px !important'
        },
        ['@media (max-width:700px)']: {
            top: '20px !important',
            left: '-30px !important'
        },
        ['@media (max-width:376px)']: {
            left: '-7px !important',
        },
        '&::after': {
            top: '-20px',
            left: '133px',
            marginLeft: '-10px',
            content: `" "`,
            position: 'absolute',
            display: 'block',
            width: 0,
            height: 0,
            border: '10px solid transparent',
            borderBottomColor: '#ffffff',
            transitionDuration: '1s',
        }
    },
}));

export function LogoutModal(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);


    const dispatch = useDispatch()

    function handleLogOut(event) {
        dispatch(signoutCreate())
        localStorage.clear()
        handleClose(event)
    }
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };


    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);


    return (
        <div className={classes.root} >
            <img src="https://cdn.blotclub.am/site/images/logo_new.png" alt="logo_new" className='avatar'
                ref={anchorRef}
                onClick={handleToggle} />
            <Popper className={classes.conent} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    <MenuItem onClick={(event) => handleLogOut(event)} className={classes.menuitem}  >
                                        <img src={powerOff} alt="powerOff" className={classes.icon} />  Sign out
                                 </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

        </div>
    );
}