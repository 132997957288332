import React from 'react'
import EditIcon from '@material-ui/icons/Edit';
import './style.scss'

export function EditButton({ handleEdit }) {
    return (
        <button onClick={handleEdit} className='edit-btn'>
            <EditIcon style={{ color: 'white' }} fontSize='small' />
        </button>
    )
}
