/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CachedIcon from "@material-ui/icons/Cached";
import { RadioComp } from "../../components/Radio/Radio";
import { MaterialUIPickers } from "../../components/DatePicker/DatePicker";
import { Chart } from "../../components/Chart/Chart";
import { purchasesCreate } from "../../store/actions/purchases";
import { gamesCreate } from "../../store/actions/games";
import { Pagination } from "../../components/Pagination/Pagination";
import { getDays } from "../../components/Methods/Methods";
import { HideShowSeries } from "../../components/HideShowSeries/HideShowSeries";
import { SearchLoudingIcon } from "../../components/SearchLoudingIcon/SearchLoudingIcon";
import {
  createDataForDay,
  changeDataToMonth,
  dataMutation,
  createData,
  getTotal,
  purchase,
  getCount,
  gamesSeries,
  changeDataToMonthPurch,
} from "./dataForCharts";
import { Warning } from "../../components/Warning/Warning";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { getTournamentCreate } from "../../store/actions/getTournament";
import { Tournament } from "../../components/Tournament/Tournament";
import { toIso } from "../../components/Methods/Methods";
import "./style.scss";

export function Statistics() {
  const dispatch = useDispatch();
  const stateAuth = useSelector((state) => state.authReducer);
  const { role } = stateAuth;

  const d = new Date();
  const [endDatePurch, setEndDatePucrh] = useState(
    new Date(d.setDate(d.getDate() + 1))
  );
  const [startDatePurch, setStartDatePucrh] = useState(new Date());
  const t = new Date();

  const [endDateGame, setEndDateGame] = useState(
    new Date(t.setDate(t.getDate() + 1))
  );

  const [startDateGame, setStartDateGame] = useState(new Date());

  const stateGames = useSelector((state) => state.gameReduser);
  const copystateGames = { ...stateGames };
  const { loadingGame, games } = copystateGames;
  const statePurch = useSelector((state) => state.purchaseReducer);
  const copystatePurch = { ...statePurch };
  const { loadingPurch, purchases } = copystatePurch;

  const [todos, setToto] = useState(games);
  const [todosPurch, setTotoPurch] = useState([]);
  const [isShowDayPurch, setShowDayPurch] = useState(false);
  const [isShowMonthPurch, setShowMonthPurch] = useState(false);
  const [isDifferentYearPurch, setIsDifferentYearPurch] = useState(false);

  const [isShowMonth, setShowMonth] = useState(false);
  const [isDifferentYear, setIsDifferentYear] = useState(false);
  function countBetwinDate(st, end) {
    if (end.getTime() - st.getTime() > 2590000000) {
      setShowMonth(true);
    } else {
      setShowMonth(false);
    }
  }
  function countYearBetwinDate(st, end) {
    if (end.getFullYear() === st.getFullYear()) {
      setIsDifferentYear(false);
    } else {
      setIsDifferentYear(true);
    }
  }
  // 86400000
  function countBetwinDatePurch(st, end) {
    let diff = end.getTime() - st.getTime();
    if (diff > 2590000000) {
      setShowMonthPurch(false);
    } else if (diff > 86500000 && diff < 2590000000) {
      setShowMonthPurch(false);
    } else {
      setShowDayPurch(true);
    }
  }

  function countYearBetwinDatePurch(st, end) {
    if (end.getFullYear() === st.getFullYear()) {
      setIsDifferentYearPurch(false);
    } else {
      setIsDifferentYearPurch(true);
    }
  }

  //set purchase type  fb  vk ....
  const [isSetRadio, setRadeo] = useState("fb");
  const handleChange = (val) => {
    setRadeo(val);
  };

  const handleDateChangePurchase = (date, type) => {
    if (type === "start") setStartDatePucrh(date);
    if (type === "end") setEndDatePucrh(date);
  };

  const handleDateChangeGame = (date, type) => {
    if (type === "start") setStartDateGame(date);
    if (type === "end") setEndDateGame(date);
    countYearBetwinDate(startDateGame, endDateGame);
  };

  function getTimeZone() {
    return new Date().getTimezoneOffset() / 60;
  }

  const addHours = function (date, h) {
    date.setTime(date.getTime() + h * 60 * 60 * 1000);
    return date;
  };

  const handleRefreshGames = () => {
    countBetwinDate(startDateGame, endDateGame);
    countYearBetwinDate(startDateGame, endDateGame);
    dispatch(
      gamesCreate({
        start: addHours(startDateGame, -getTimeZone()),
        end: addHours(endDateGame, -getTimeZone()),
      })
    );
  };

  const handleRefreshPurch = () => {
    countYearBetwinDatePurch(startDatePurch, endDatePurch);
    countBetwinDatePurch(startDatePurch, endDatePurch);

    dispatch(
      purchasesCreate({
        start: addHours(startDatePurch, -getTimeZone()),
        end: addHours(endDatePurch, -getTimeZone()),
      })
    );
  };

  useEffect(() => {
    countYearBetwinDate(startDateGame, endDateGame);
    dispatch(
      gamesCreate({ start: toIso(startDateGame), end: toIso(endDateGame) })
    );
    dispatch(
      purchasesCreate({
        start: toIso(startDatePurch),
        end: toIso(endDatePurch),
      })
    );
  }, []);

  const styleRadeo = { marginTop: "-3px", marginLeft: "-3px" };
  //----------------------------------------------------------functions muteting dates for props of charts((

  let start = startDateGame && startDateGame.toString().slice(4, 7);
  let end = endDateGame && endDateGame.toString().slice(4, 7);
  let startPurch = startDatePurch && startDatePurch.toString().slice(4, 7);
  let endPurch = endDatePurch && endDatePurch.toString().slice(4, 7);
  const [arrTotal, setArrTotal] = useState([]);
  const [arrMicro, setArrMicro] = useState([]);
  const [arrBig, setArrBig] = useState([]);
  const [arrTraining, setArrTraining] = useState([]);
  const [arrBonus, setArrBonus] = useState([]);
  const [arrProf, setArrProf] = useState([]);
  const [arrUnfinished, setArrUnfinished] = useState([]);
  const [arrFast, setArrFast] = useState([]);
  const [arrClassic, setArrClassic] = useState([]);
  const [arrFriends, setArrFriends] = useState([]);

  useEffect(() => {
    if (games) {
      setArrTotal(dataMutation(games, "total", startDateGame, endDateGame));
      setArrMicro(dataMutation(games, "micro", startDateGame, endDateGame));
      setArrBig(dataMutation(games, "big", startDateGame, endDateGame));
      setArrTraining(
        dataMutation(games, "training", startDateGame, endDateGame)
      );
      setArrBonus(dataMutation(games, "training", startDateGame, endDateGame));
      setArrProf(dataMutation(games, "prof", startDateGame, endDateGame));
      setArrUnfinished(
        dataMutation(games, "unfinished", startDateGame, endDateGame)
      );
      setArrFast(dataMutation(games, "fast", startDateGame, endDateGame));
      setArrClassic(dataMutation(games, "classic", startDateGame, endDateGame));
      setArrFriends(dataMutation(games, "friends", startDateGame, endDateGame));
    }
  }, [games]);

  const [dataForPurch, setDataForPurch] = useState([]);

  useEffect(() => {
    if (purchases)
      setDataForPurch(
        createData(
          purchases[isSetRadio],
          "createdAt",
          startDatePurch,
          endDatePurch
        )
      );
    if (isShowDayPurch)
      setDataForPurch(
        createDataForDay(
          purchases[isSetRadio],
          "createdAt",
          startDatePurch,
          endDatePurch,
          isShowDayPurch
        )
      );
  }, [isSetRadio, purchases]);

  let arrTotalMonth = [];
  let arrMicroMonth = [];
  let arrBigMonth = [];
  let arrTrainingMonth = [];
  let arrBonusMonth = [];
  let arrProfMonth = [];
  let arrUnfinishedMonth = [];
  let arrFastMonth = [];
  let arrClassicMonth = [];
  let arrFriendsMonth = [];

  if (games) {
    if (isShowMonth)
      arrTotalMonth = [
        ...changeDataToMonth(arrTotal, start, end, isDifferentYear),
      ];
    if (isShowMonth)
      arrMicroMonth = [
        ...changeDataToMonth(arrMicro, start, end, isDifferentYear),
      ];
    if (isShowMonth)
      arrBigMonth = [...changeDataToMonth(arrBig, start, end, isDifferentYear)];
    if (isShowMonth)
      arrTrainingMonth = [
        ...changeDataToMonth(arrTraining, start, end, isDifferentYear),
      ];
    if (isShowMonth)
      arrBonusMonth = [
        ...changeDataToMonth(arrBonus, start, end, isDifferentYear),
      ];
    if (isShowMonth)
      arrProfMonth = [
        ...changeDataToMonth(arrProf, start, end, isDifferentYear),
      ];
    if (isShowMonth)
      arrUnfinishedMonth = [
        ...changeDataToMonth(arrUnfinished, start, end, isDifferentYear),
      ];
    if (isShowMonth)
      arrFastMonth = [
        ...changeDataToMonth(arrFast, start, end, isDifferentYear),
      ];
    if (isShowMonth)
      arrClassicMonth = [
        ...changeDataToMonth(arrClassic, start, end, isDifferentYear),
      ];
    if (isShowMonth)
      arrFriendsMonth = [
        ...changeDataToMonth(arrFriends, start, end, isDifferentYear),
      ];
  }

  let dataPurch = [];
  if (purchases && purchases[isSetRadio]) {
    if (isShowMonthPurch)
      dataPurch = [
        ...changeDataToMonthPurch(
          dataForPurch,
          startPurch,
          endPurch,
          isDifferentYearPurch
        ),
      ];
  }

  //----------------------------------------------------------functions muteting dates for props of charts))

  useEffect(() => {
    games &&
      games.sort((a, b) =>
        new Date(a.started) < new Date(b.started) ? 1 : -1
      );
    setToto(games);
  }, [games]);

  useEffect(() => {
    if (purchases) {
      setTotoPurch(purchases[isSetRadio]);
    }
  }, [purchases, isSetRadio]);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [selectPage, setSelectPage] = useState(0);
  const indexOfLastTodo = currentPage * 15;
  const indexOfFirstTodo = indexOfLastTodo - 15;
  let pageCount = 0;
  let pageCountPurch = 0;
  if (todos && todos.length) {
    pageCount = Math.ceil(todos.length / 15);
  }

  if (todosPurch && todosPurch.length) {
    pageCountPurch = Math.ceil(todosPurch.length / 15);
  }
  let currentTodos = [];
  if (todos) {
    currentTodos = todos.slice(indexOfFirstTodo, indexOfLastTodo);
  }
  let currentTodosPurch = [];
  if (todosPurch) {
    currentTodosPurch = todosPurch.slice(indexOfFirstTodo, indexOfLastTodo);
  }
  const onChangePage = (i) => {
    setSelectPage(i.selected);
    setCurrentPage(i.selected + 1);
  };
  //sort handlers ------------------------------------))

  const [isSortType, setSortType] = useState(false);
  const [isSortUserId, setSortUserId] = useState(false);
  const [isSortcreatedAt, setSortcreatedAt] = useState(false);
  const [isSortZone, setSortZone] = useState(false);
  const [isSortChips, setSortChips] = useState(false);
  const [isSortAmount, setSortAmount] = useState(false);
  const [isSortCurrency, setSortCurrency] = useState(false);
  const [isSortRoom, setSortRoom] = useState(false);
  const [isSortStarted, setSortStarted] = useState(false);
  const [isSortStatus, setSortStatus] = useState(false);

  const handleSortStatus = () => {
    setSortStatus(!isSortStatus);
    if (isSortStatus) {
      todos.sort((a, b) =>
        a.active.toString().toLowerCase() > b.active.toString().toLowerCase()
          ? 1
          : -1
      );
    } else {
      todos.sort((a, b) =>
        a.active.toString().toLowerCase() < b.active.toString().toLowerCase()
          ? 1
          : -1
      );
    }
  };

  const handleSortRoom = () => {
    setSortRoom(!isSortRoom);
    if (isSortRoom) {
      todos.sort((a, b) =>
        a.roomType.toLowerCase() > b.roomType.toLowerCase() ? 1 : -1
      );
    } else {
      todos.sort((a, b) =>
        a.roomType.toLowerCase() < b.roomType.toLowerCase() ? 1 : -1
      );
    }
  };

  const handleSortStarted = () => {
    setSortStarted(!isSortStarted);
    if (isSortStarted) {
      todos.sort((a, b) =>
        new Date(a.started) < new Date(b.started) ? 1 : -1
      );
    } else {
      todos.sort((a, b) =>
        new Date(a.started) > new Date(b.started) ? 1 : -1
      );
    }
  };

  const handleSortCurrency = () => {
    setSortCurrency(!isSortCurrency);
    if (isSortCurrency) {
      todosPurch.sort((a, b) =>
        a.currency.toLowerCase() > b.currency.toLowerCase() ? 1 : -1
      );
    } else {
      todosPurch.sort((a, b) =>
        a.currency.toLowerCase() < b.currency.toLowerCase() ? 1 : -1
      );
    }
  };
  const handleSortChips = () => {
    setSortChips(!isSortChips);
    if (!isSortChips) {
      todosPurch.sort((a, b) => b.chips - a.chips);
      setTotoPurch([...todosPurch]);
    } else {
      todosPurch.sort((a, b) => a.chips - b.chips);
      setTotoPurch([...todosPurch]);
    }
  };
  const handleSortAmount = () => {
    setSortAmount(!isSortAmount);
    if (!isSortAmount) {
      todosPurch.sort((a, b) => b.amount - a.amount);
      setTotoPurch([...todosPurch]);
    } else {
      todosPurch.sort((a, b) => a.amount - b.amount);
      setTotoPurch([...todosPurch]);
    }
  };
  const handleSortType = () => {
    setSortType(!isSortType);
    if (isSortType) {
      todosPurch.sort((a, b) =>
        a.type.toLowerCase() > b.type.toLowerCase() ? 1 : -1
      );
    } else {
      todosPurch.sort((a, b) =>
        a.type.toLowerCase() < b.type.toLowerCase() ? 1 : -1
      );
    }
  };
  const handleSortZone = () => {
    setSortZone(!isSortZone);
    if (isSortZone) {
      todosPurch.sort((a, b) =>
        a.zone.toLowerCase() > b.zone.toLowerCase() ? 1 : -1
      );
    } else {
      todosPurch.sort((a, b) =>
        a.zone.toLowerCase() < b.zone.toLowerCase() ? 1 : -1
      );
    }
  };
  const handleSortUserId = () => {
    setSortUserId(!isSortUserId);
    if (isSortUserId) {
      todosPurch.sort((a, b) =>
        a.userId.toLowerCase() > b.userId.toLowerCase() ? 1 : -1
      );
    } else {
      todosPurch.sort((a, b) =>
        a.userId.toLowerCase() < b.userId.toLowerCase() ? 1 : -1
      );
    }
  };

  const handleSortcreatedAt = () => {
    setSortcreatedAt(!isSortcreatedAt);
    if (isSortcreatedAt) {
      todosPurch.sort((a, b) =>
        new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
      );
    } else {
      todosPurch.sort((a, b) =>
        new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1
      );
    }
  };

  //sort handlers ------------------------------------((

  const renderList = currentTodos.map((row) => {
    return (
      <tr key={row._id}>
        <td>{row.roomType}</td>
        <td>
          <span>{getDays(row.started)}</span>
        </td>
        <td>{row.active ? "active" : "finished"}</td>
        <td>
          {row.users.map((el) => (
            <span key={el.userId}>{el.userId + ", "}</span>
          ))}
        </td>
      </tr>
    );
  });

  const renderListPurchase = currentTodosPurch.map((row) => {
    return (
      <tr key={row.id}>
        <td>{row.type}</td>
        <td>
          <span>{getDays(row.createdAt)}</span>
        </td>
        <td>{row.userId}</td>
        <td>{row.zone}</td>
        <td>{row.chips}</td>
        <td>{row.amount}</td>
        <td>{row.currency}</td>
      </tr>
    );
  });

  //gamesSeries -----------------------

  const [isCheckSeries, setCheckSeries] = useState([]);
  const handleChangeSeries = (val) => {
    if (!isCheckSeries.includes(val)) {
      setCheckSeries([...isCheckSeries, val]);
    } else {
      isCheckSeries.splice(isCheckSeries.indexOf(val), 1);
      setCheckSeries([...isCheckSeries]);
    }
  };
  //tournament/getTournamentReports-----------------------
  useEffect(() => {
    dispatch(getTournamentCreate());
  }, [dispatch]);

  const stateTournament = useSelector((state) => state.tournamentReducer);
  const copyStateTournament = { ...stateTournament };
  const { tournament } = copyStateTournament;
  const [value, setValue] = useState("");
  const handleChangeTourname = (e) => {
    setValue(e.target.value);
  };

  const [tourTodo, setTourTodo] = useState([]);
  useEffect(() => {
    setTourTodo(tournament);
  }, [tournament]);

  const filterName =
    tournament &&
    tournament.filter(
      (el) => el.name[2].toLowerCase() === value.toLowerCase() && el
    );

  useEffect(() => {
    if (filterName && filterName.length > 0) {
      setTourTodo(filterName);
    } else {
      setTourTodo(tournament);
    }
  }, [value]);

  return (
    <>
      {" "}
      {(role === 4 || role === 2) && (
        <>
          <Warning
            className="warning"
            text="Be careful getting info from big date diapason can overload API"
          />
          <div className="panel profile-page animated zoomIn resposive">
            <div className="panel-body">
              <div className="body-content">
                <div className="header-content">
                  <h3 className="header">
                    Purchases -{" "}
                    {purchases &&
                      purchases[isSetRadio] &&
                      purchases[isSetRadio].length}
                  </h3>
                  <button
                    className="btn"
                    onClick={handleRefreshPurch}
                    style={
                      loadingPurch ? { background: "rgb(170, 170, 170)" } : null
                    }
                  >
                    {loadingPurch ? (
                      <SearchLoudingIcon />
                    ) : (
                      <CachedIcon
                        fontSize={"small"}
                        style={{ color: "white" }}
                      />
                    )}
                  </button>
                </div>
                <div className="labels-wrapper">
                  {purchase.map((el, i) => (
                    <label
                      key={el}
                      className="label"
                      htmlFor="title"
                      style={
                        i === 6
                          ? { fontSize: 14.5, marginRight: 0 }
                          : { fontSize: 14.5 }
                      }
                    >
                      {el}
                      <RadioComp
                        size="small"
                        styleRadeo={styleRadeo}
                        handleChange={(e) => handleChange(el)}
                        checked={el === isSetRadio ? true : false}
                      />
                    </label>
                  ))}
                </div>

                <div className="label-wrapp">
                  <label htmlFor="Title">Start :</label>
                  <MaterialUIPickers
                    width={"100%"}
                    selectedDate={startDatePurch}
                    handleDateChange={(e) =>
                      handleDateChangePurchase(e, "start")
                    }
                  />
                </div>
                <div className="label-wrapp">
                  <label htmlFor="Title">End :</label>
                  <MaterialUIPickers
                    width={"100%"}
                    selectedDate={endDatePurch}
                    handleDateChange={(e) => handleDateChangePurchase(e, "end")}
                  />
                </div>
                <hr />
                <div className="count">
                  <span>
                    Total chips{" "}
                    {(todosPurch && getTotal(todosPurch, "chips")) || 0}{" "}
                  </span>
                  <span>
                    Total amount{" "}
                    {(todosPurch && getTotal(todosPurch, "amount")) || 0}
                  </span>
                </div>
                <div className="horizontal-scroll">
                  <table className="table">
                    <thead>
                      <tr className="sortable">
                        <th onClick={handleSortType}>
                          Type{" "}
                          {!isSortType ? (
                            <ArrowDropDownIcon
                              style={{ verticalAlign: "middle" }}
                              fontSize={"small"}
                            />
                          ) : (
                            <ArrowDropUpIcon
                              style={{ verticalAlign: "middle" }}
                              fontSize={"small"}
                            />
                          )}
                        </th>
                        <th onClick={handleSortcreatedAt}>
                          Date{" "}
                          {!isSortcreatedAt ? (
                            <ArrowDropDownIcon
                              style={{ verticalAlign: "middle" }}
                              fontSize={"small"}
                            />
                          ) : (
                            <ArrowDropUpIcon
                              style={{ verticalAlign: "middle" }}
                              fontSize={"small"}
                            />
                          )}
                        </th>
                        <th onClick={handleSortUserId}>
                          User Id
                          {!isSortUserId ? (
                            <ArrowDropDownIcon
                              style={{ verticalAlign: "middle" }}
                              fontSize={"small"}
                            />
                          ) : (
                            <ArrowDropUpIcon
                              style={{ verticalAlign: "middle" }}
                              fontSize={"small"}
                            />
                          )}
                        </th>
                        <th onClick={handleSortZone}>
                          Zone
                          {!isSortZone ? (
                            <ArrowDropDownIcon
                              style={{ verticalAlign: "middle" }}
                              fontSize={"small"}
                            />
                          ) : (
                            <ArrowDropUpIcon
                              style={{ verticalAlign: "middle" }}
                              fontSize={"small"}
                            />
                          )}
                        </th>
                        <th onClick={handleSortChips}>
                          Chips
                          {!isSortChips ? (
                            <ArrowDropDownIcon
                              style={{ verticalAlign: "middle" }}
                              fontSize={"small"}
                            />
                          ) : (
                            <ArrowDropUpIcon
                              style={{ verticalAlign: "middle" }}
                              fontSize={"small"}
                            />
                          )}
                        </th>
                        <th onClick={handleSortAmount}>
                          Amount
                          {!isSortAmount ? (
                            <ArrowDropDownIcon
                              style={{ verticalAlign: "middle" }}
                              fontSize={"small"}
                            />
                          ) : (
                            <ArrowDropUpIcon
                              style={{ verticalAlign: "middle" }}
                              fontSize={"small"}
                            />
                          )}
                        </th>
                        <th onClick={handleSortCurrency}>
                          Currency
                          {!isSortCurrency ? (
                            <ArrowDropDownIcon
                              style={{ verticalAlign: "middle" }}
                              fontSize={"small"}
                            />
                          ) : (
                            <ArrowDropUpIcon
                              style={{ verticalAlign: "middle" }}
                              fontSize={"small"}
                            />
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>{renderListPurchase}</tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="6" className="text-center">
                          <div className="nav">
                            {pageCountPurch > 1 && (
                              <Pagination
                                selectPage={selectPage}
                                pageCount={pageCountPurch}
                                onChangePage={(i) => onChangePage(i)}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <Chart
                  dataForPurch={
                    isShowMonthPurch ? dataPurch : todosPurch && dataForPurch
                  }
                />
              </div>
            </div>
          </div>
          {(role === 3 || role === 4) && (
            <>
              {" "}
              <div
                className="panel profile-page animated zoomIn resposive"
                style={{ marginBottom: 15 }}
              >
                <div className="panel-body">
                  <div className="body-content">
                    <div className="header-content">
                      <h3 className="header">
                        Games - {games && games.length}
                      </h3>
                      <button
                        className="btn"
                        onClick={handleRefreshGames}
                        style={
                          loadingGame
                            ? { background: "rgb(170, 170, 170)" }
                            : null
                        }
                      >
                        {loadingGame ? (
                          <SearchLoudingIcon />
                        ) : (
                          <CachedIcon
                            fontSize={"small"}
                            style={{ color: "white" }}
                          />
                        )}
                      </button>
                      <span className="live">
                        Live - {games && games.filter((el) => el.active).length}{" "}
                      </span>
                    </div>

                    <div className="label-wrapp">
                      <label htmlFor="Title">Start :</label>
                      <MaterialUIPickers
                        width={"100%"}
                        selectedDate={startDateGame}
                        handleDateChange={(e) =>
                          handleDateChangeGame(e, "start")
                        }
                      />
                    </div>
                    <div className="label-wrapp" style={{ marginBottom: 10 }}>
                      <label htmlFor="Title">End :</label>
                      <MaterialUIPickers
                        width={"100%"}
                        selectedDate={endDateGame}
                        handleDateChange={(e) => handleDateChangeGame(e, "end")}
                      />
                    </div>
                    <Chart
                      dataArrTotal={
                        !isCheckSeries.includes("total") &&
                        (isShowMonth ? arrTotalMonth : arrTotal)
                      }
                      dataArrMicro={
                        !isCheckSeries.includes("micro") &&
                        (isShowMonth ? arrMicroMonth : arrMicro)
                      }
                      dataArrBig={
                        !isCheckSeries.includes("big") &&
                        (isShowMonth ? arrBigMonth : arrBig)
                      }
                      dataArrFriends={
                        !isCheckSeries.includes("friends") &&
                        (isShowMonth ? arrFriendsMonth : arrFriends)
                      }
                      dataArrTraining={
                        !isCheckSeries.includes("training") &&
                        (isShowMonth ? arrTrainingMonth : arrTraining)
                      }
                      dataArrBonus={
                        !isCheckSeries.includes("bonus") &&
                        (isShowMonth ? arrBonusMonth : arrBonus)
                      }
                      dataArrProf={
                        !isCheckSeries.includes("prof") &&
                        (isShowMonth ? arrProfMonth : arrProf)
                      }
                      dataArrUnfinished={
                        !isCheckSeries.includes("unfinished") &&
                        (isShowMonth ? arrUnfinishedMonth : arrUnfinished)
                      }
                      dataArrFast={
                        !isCheckSeries.includes("fast") &&
                        (isShowMonth ? arrFastMonth : arrFast)
                      }
                      dataArrClassic={
                        !isCheckSeries.includes("classic") &&
                        (isShowMonth ? arrClassicMonth : arrClassic)
                      }
                    />

                    <div className="check-btn-content">
                      {gamesSeries.map((el, i) => (
                        <div className="check-btn-wrapp" key={el.name}>
                          <HideShowSeries
                            backgroundColor={el.color}
                            check={isCheckSeries.includes(el.name)}
                            handleChangeSeries={(e) =>
                              handleChangeSeries(el.name)
                            }
                          />
                          <span>
                            {el.name} {" - "}{" "}
                            {el.name === "total"
                              ? games && games.length
                              : games && getCount(games, el.name)}
                          </span>
                        </div>
                      ))}
                    </div>
                    <hr />
                    <div className="horizontal-scroll">
                      <table className="table">
                        <thead>
                          <tr className="sortable">
                            <th onClick={handleSortRoom}>
                              Room{" "}
                              {!isSortRoom ? (
                                <ArrowDropDownIcon
                                  style={{ verticalAlign: "middle" }}
                                  fontSize={"small"}
                                />
                              ) : (
                                <ArrowDropUpIcon
                                  style={{ verticalAlign: "middle" }}
                                  fontSize={"small"}
                                />
                              )}
                            </th>
                            <th onClick={handleSortStarted}>
                              Date
                              {!isSortStarted ? (
                                <ArrowDropDownIcon
                                  style={{ verticalAlign: "middle" }}
                                  fontSize={"small"}
                                />
                              ) : (
                                <ArrowDropUpIcon
                                  style={{ verticalAlign: "middle" }}
                                  fontSize={"small"}
                                />
                              )}
                            </th>
                            <th onClick={handleSortStatus}>
                              Status
                              {!isSortStatus ? (
                                <ArrowDropDownIcon
                                  style={{ verticalAlign: "middle" }}
                                  fontSize={"small"}
                                />
                              ) : (
                                <ArrowDropUpIcon
                                  style={{ verticalAlign: "middle" }}
                                  fontSize={"small"}
                                />
                              )}
                            </th>
                            <th>Users</th>
                          </tr>
                        </thead>
                        <tbody>{renderList}</tbody>
                        <tfoot>
                          <tr>
                            <td colSpan="6" className="text-center">
                              <div className="nav">
                                {pageCount > 1 && (
                                  <Pagination
                                    selectPage={selectPage}
                                    pageCount={pageCount}
                                    onChangePage={(i) => onChangePage(i)}
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <Tournament
                tournament={tourTodo}
                handleChange={handleChangeTourname}
                value={value}
              />
            </>
          )}
        </>
      )}{" "}
    </>
  );
}
