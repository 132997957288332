import {
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FILED,
  SIGN_OUT_START,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FILED,
  CHECK_LOGIN_START,
  CHECK_LOGIN_SUCCESS,
  CHECK_LOGIN_FILED,
} from "../action-types/actionTypes";
import { api } from "../../api/service";

export const signinCreate = (data) => {
  return async (dispatch) => {
    dispatch({
      type: AUTH_START,
    });
    try {
      const response = await api.post("/login", data);
      if (response.data.status) {
        localStorage.setItem("token", response.data.user.id);
      }
      dispatch({
        type: AUTH_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: AUTH_FILED,
        error: { message: "Something went wrong." },
      });
    }
  };
};

export const checkLoginCreate = (data) => {
  return async (dispatch) => {
    dispatch({
      type: CHECK_LOGIN_START,
    });

    try {
      const response = await api.post("/checkLogin");
      console.log("response", response);
      if (response.data.status) {
        localStorage.setItem("token", response.data.user.id);
      }
      dispatch({
        type: CHECK_LOGIN_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: CHECK_LOGIN_FILED,
        error: e,
      });
    }
  };
};

export const signoutCreate = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SIGN_OUT_START,
    });

    try {
      const response = await api.post("/signOut");
      dispatch({
        type: SIGN_OUT_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: SIGN_OUT_FILED,
        error: { message: "Something went wrong." },
      });
    }
  };
};
