import {
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER__FILED,
  INCREMENT_DECREMENT,
  CHARGE_CHIP_START,
  CHARGE_CHIP_SUCCESS,
  CHARGE_CHIP__FILED,
} from "../action-types/actionTypes";

const initialState = {
  loading: false,
  user: null,
  error: null,
  redChip: 0,
  yellowChip: 0,
  greenChip: 0,
  success: false,
};

export function getUserData(state = initialState, action) {
  switch (action.type) {
    case INCREMENT_DECREMENT:
      return {
        ...state,
        redChip:
          action.payload[1] === "red"
            ? state.user.red + Number(action.payload[0])
            : state.redChip,
        yellowChip:
          action.payload[1] === "yellow"
            ? state.user.yellow + Number(action.payload[0])
            : state.yellowChip,
        greenChip:
          action.payload[1] === "green"
            ? state.user.green + Number(action.payload[0])
            : state.greenChip,
      };
    case GET_USER_START:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        redChip: action.payload.user.red,
        yellowChip: action.payload.user.yellow,
        greenChip: action.payload.user.green,
        success: false,
      };
    case GET_USER__FILED:
      return {
        ...state,
        loading: false,
        error: "samething went wrong",
      };
    case CHARGE_CHIP_START:
      return {
        ...state,
        success: false,
      };
    case CHARGE_CHIP_SUCCESS:
      return {
        ...state,
        success: action.payload.status,
      };
    case CHARGE_CHIP__FILED:
      return {
        ...state,
        success: false,
        error: "samething went wrong",
      };
    default:
      return state;
  }
}
