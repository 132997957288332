import React, { useState } from "react";
import { Link } from "react-router-dom";
import home from "@iconify/icons-oi/home";
import iosPulseStrong from "@iconify/icons-ion/ios-pulse-strong";
import statsBars from "@iconify/icons-ion/stats-bars";
import iosMailUnread from "@iconify/icons-ion/ios-mail-unread";
import { useSelector } from "react-redux";
import notificationsOutline from "@iconify/icons-ion/notifications-outline";
import { Icon } from "@iconify/react";
import { debounce } from "debounce";
import "./style.scss";

export function BurgerMenu({ isShowModal, windowWidth }) {
    const stateAuth = useSelector((state) => state.authReducer);
    const { role } = stateAuth;
    const [getY, setY] = useState(0);
    const styles = {
        transform: `translate(${0}px, ${getY - 93}px)`,
    };
    const handleMove = (e) => {
        setY(e.clientY);
    };
    const styleBurgershow = {
        width: 180,
        transition: "all 0.8s ease",
    };

    const styleBurgerhide = {
        width: 52,
        transition: "all 0.8s ease",
    };

    function createHide() {
        if (windowWidth < 500) {
            styleBurgerhide.width = 0;
        } else {
            styleBurgerhide.width = 52;
        }
        return styleBurgerhide;
    }

    let path = window.location.pathname;
    return (
        <div
            className="burger-container"
            style={isShowModal ? styleBurgershow : createHide()}
        >
            <ul onMouseMove={(e) => debounce(handleMove(e), 700)}>
                <div className="hover-el" style={styles}></div>
                {(role === 3 || role === 4) && (
                    <>
                        {" "}
                        <li className={path === "/dashboard" ? "active" : null}>
                            <Link to="/dashboard">
                                <div className="icon-wrap">
                                    <Icon height={"0.75rem"} icon={home} />
                                </div>
                                <span> Dashboard</span>
                            </Link>
                        </li>
                        <li
                            className={
                                path === "/notifications" ? "active" : null
                            }
                        >
                            <Link to="/notifications">
                                <div className="icon-wrap">
                                    {" "}
                                    <Icon
                                        width={"0.8rem"}
                                        icon={notificationsOutline}
                                    />
                                </div>
                                <span>Notifications</span>
                            </Link>
                        </li>
                        <li className={path === "/active" ? "active" : null}>
                            <Link to="/active">
                                <div className="icon-wrap">
                                    <Icon
                                        width={"0.8rem"}
                                        icon={iosPulseStrong}
                                    />
                                </div>
                                <span> Active Games</span>
                            </Link>
                        </li>
                    </>
                )}
                <li className={path === "/statistics" ? "active" : null}>
                    <Link to="/statistics">
                        <div className="icon-wrap">
                            <Icon width={"0.8rem"} icon={statsBars} />
                        </div>
                        <span>Statistics</span>
                    </Link>
                </li>
                <li className={path === "/teamGame" ? "active" : null}>
                    <Link to="/teamGame">
                        <div className="icon-wrap">
                            <Icon width={"0.8rem"} icon={statsBars} />
                        </div>
                        <span>Team Game</span>
                    </Link>
                </li>
                {(role === 3 || role === 4) && (
                    <li className={path === "/usergifts" ? "active" : null}>
                        <Link to="/usergifts">
                            <div className="icon-wrap">
                                <Icon height={"0.8rem"} icon={iosMailUnread} />
                            </div>
                            <span> User Gifts</span>
                        </Link>
                    </li>
                )}
            </ul>
        </div>
    );
}
