import {
    FINISHED_GAME_START,
    FINISHED_GAME_SUCCESS,
    FINISHED_GAME_FILED,
} from "../action-types/actionTypes";
import { api } from '../../api/service'


export const finishedGamesCreate = (data) => {
    return async dispatch => {
        dispatch({
            type: FINISHED_GAME_START,
        });
        try {
            const response = await api.post('/finishedGames', data);
            dispatch({
                type: FINISHED_GAME_SUCCESS,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: FINISHED_GAME_FILED,
                error: { message: "Something went wrong." }
            });
        }
    };
}