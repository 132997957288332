import React from 'react';
import { Table } from '../Table/Table';
import { TextFields } from '../TextField/TextField';
import './style.scss';
import { tr } from 'date-fns/locale';

export function Tournament({ tournament, handleChange, value }) {
    const thArr = ['TourId', 'Name', 'Rewards Length',
        'Played Users Count', 'All Games Played Users Count', 'Joined Users Count'];
    const styles = { fontWeight: 400 };



    const renderList = tournament && tournament.map(el => {
        return <tr key={el._id}>
            <td>{el.tourId}</td>
            <td>{el.name[2]}</td>
            <td>{el.rewardsLength}</td>
            <td>{el.playedUsersCount}</td>
            <td>{el.allGamesPlayedUsersCount}</td>
            <td>{el.joinedUsersCount}</td>
        </tr>;
    });

    return (
        <div className='panel profile-page animated zoomIn resposive' id='res'  >
            <div className="panel-body">
                <div className="body-content">
                    <div className="header-content">
                        <h3 className="header">Tournament</h3>
                    </div>
                    <TextFields
                        label="search-name"
                        handleChange={handleChange}
                        value={value}
                        width={130}
                    />
                    <hr />
                    <div className="horizontal-scroll">
                        <Table
                            thArr={thArr}
                            styles={styles}
                            renderList={renderList}
                        />
                    </div>
                </div>
            </div>
        </div>

    );
}


// /admin/tournament/getTournamentReport
// 4:27
// es meki mej el tournamentId petqa poxances