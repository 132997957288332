import {
    CHARGE_BACK_START,
    CHARGE_BACK_SUCCESS,
    CHARGE_BACK_FILED,
} from "../action-types/actionTypes";
import { api } from '../../api/service'



export const chargeBackCreate = data => {
    return async dispatch => {
        dispatch({
            type: CHARGE_BACK_START,
        });
        try {
            const response = await api.post('/chargeBack', data);
            dispatch({
                type: CHARGE_BACK_SUCCESS,
                payload: response
            });
        } catch (e) {
            dispatch({
                type: CHARGE_BACK_FILED,
                error: { message: "Something went wrong." }
            });
        }
    };
}