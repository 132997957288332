import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import DoneIcon from "@material-ui/icons/Done";
import "./style.scss";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px !important",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "5px",
        border: "none !important",
        boxShadow: theme.shadows[5],
        width: 900,
        padding: "0px 2px",
    },
    title: {
        color: "#ffffff !important",
        fontWeight: "300",
        backgroundColor: "rgba(144, 185, 0, 0.85)",
        margin: 0,
        fontSize: 14,
        padding: 10,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
    },
    span: {
        display: "flex",
        alignItems: "center",
    },
}));

function SuccesModalMemo({
    handleOpenSucces,
    openSuccess,
    handleCloseSuccsess,
    handleOkSuccsess,
}) {
    const classes = useStyles();

    return (
        <div className="succes-modal-content">
            <Modal
                id="modal-succ-content"
                className={classes.modal}
                open={openSuccess}
                onClose={handleCloseSuccsess}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openSuccess}>
                    <div className={classes.paper} id="modal-succes">
                        <div className={classes.title}>
                            <span className={classes.span}>
                                <DoneIcon fontSize="small" />
                                Add Chip
                            </span>
                        </div>
                        <div className="body">
                            Chips has been added successfully
                        </div>
                        <div className="btn-checkbox-wrap">
                            <button id="btn" onClick={handleOkSuccsess}>
                                Ok
                            </button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
export const SuccessModal = React.memo(SuccesModalMemo);
