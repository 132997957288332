import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDays } from '../Methods/Methods';
import { getUserNotesCreate } from '../../store/actions/notes';
import { Table } from '../Table/Table';
import { EditButton } from '../../components/EditButton/EditButton';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { ModalNot } from './ModalNot';
import { SUCCESS_TO_FALSE, EDIT_SUCCESS_TO_FALSE } from "../../store/action-types/actionTypes";

import './style.scss';

export function Notes() {
    const thArr = ['Admin', 'CreatedAt', 'UpdatedAt', 'Message', 'Edit'];
    const stateUserData = useSelector(state => state.getUserData.user);
    const copyStateUserData = { ...stateUserData };
    const { userId } = copyStateUserData;

    const stateNotes = useSelector(state => state.notesReducer);
    const copyStateNotes = { ...stateNotes };
    const { notes, success, editSuccess } = copyStateNotes;


    const dispatch = useDispatch();

    const [todos, setToto] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectPage, setSelectPage] = useState(0);

    useEffect(() => {
        if (notes) {
            notes && notes.sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1);
            setToto(notes);
        }
    }, [notes]);

    let pageCount = 0;
    if (todos) {
        pageCount = Math.ceil(todos.length / 15);
    }

    const indexOfLastTodo = currentPage * 15;
    const indexOfFirstTodo = indexOfLastTodo - 15;

    let currentTodos = [];
    if (todos) {
        currentTodos = todos.slice(indexOfFirstTodo, indexOfLastTodo);
    }
    const onChangePage = i => {
        setSelectPage(i.selected);
        setCurrentPage(i.selected + 1);
    };

    const styles = { fontWeight: 400 };

    //add----------------------
    const [isOpenModalAdd, setOpenModalAdd] = useState(false);
    const handleOpenModalNotesAdd = () => {
        setOpenModalAdd(true);
    };
    const handleCloseModalNotAdd = () => {
        setOpenModalAdd(false);
    };
    useEffect(() => {
        if (userId) dispatch(getUserNotesCreate(userId));
    }, [userId]);

    useEffect(() => {
        if (success) dispatch(getUserNotesCreate(userId));
        dispatch({ type: SUCCESS_TO_FALSE });
    }, [success]);

    useEffect(() => {
        if (editSuccess) dispatch(getUserNotesCreate(userId));
        dispatch({ type: EDIT_SUCCESS_TO_FALSE });
    }, [editSuccess]);

    const [valueAdd, setValueAdd] = useState({ userId: userId, message: '' });
    const handleChangeAdd = (e) => {
        setValueAdd({
            ...valueAdd,
            message: e.target.value,
            userId: userId
        });
    };

    //edit-------------------------------

    const [isOpenModalEdit, setOpenModalEdit] = useState(false);
    const [messId, setMesId] = useState(null);
    const handleOpenModalNotesEdit = (row) => {
        setOpenModalEdit(true);
        setMesId(row._id);
    };
    const handleCloseModalNotEdit = () => {
        setOpenModalEdit(false);
    };
    const [valueEdit, setValueEdit] = useState({ userId: userId, message: '', messageId: messId });

    const handleChangeEdit = (e) => {
        setValueEdit({
            ...valueEdit,
            message: e.target.value,
            userId: userId,
            messageId: messId
        });
    };

    const renderList = currentTodos.map((row, index) => {
        return <tr key={index}>
            <td>{row.adminName}</td>
            <td>{getDays(row.createdAt)}</td>
            <td>{getDays(row.createdAt)}</td>
            <td>{row.message}</td>
            <td><EditButton handleEdit={(e) => handleOpenModalNotesEdit(row)} /></td>
        </tr>;
    });

    return (
        <div>
            <div className="tab-content">
                <ModalNot
                    value={valueAdd}
                    handleChange={handleChangeAdd}
                    title={'Add'}
                    userId={userId}
                    isOpenModal={isOpenModalAdd}
                    handleCloseModalNot={handleCloseModalNotAdd}
                />
                <ModalNot
                    value={valueEdit}
                    handleChange={handleChangeEdit}
                    title={'Edit'}
                    userId={userId}
                    isOpenModal={isOpenModalEdit}
                    handleCloseModalNot={handleCloseModalNotEdit}
                />
                <div className="horizontal-scroll">
                    <button className='plus-btn' onClick={handleOpenModalNotesAdd} ><ControlPointIcon /></button>
                    <span style={{ marginLeft: 7 }}>Add notes</span>
                    <Table
                        thArr={thArr}
                        pageCount={pageCount}
                        selectPage={selectPage}
                        onChangePage={onChangePage}
                        renderList={renderList}
                        styles={styles} />
                </div>
            </div>
        </div>
    );
}
