import {
    GET_TOUR_NAMENT_START,
    GET_TOUR_NAMENT_SUCCESS,
    GET_TOUR_NAMENT_FILED,
} from "../action-types/actionTypes";
import { api } from '../../api/service'


export const getTournamentCreate = () =>{
    return async dispatch => {
        dispatch({
            type: GET_TOUR_NAMENT_START
        })
        try{
            const response =  await api.get('./tournament/getTournamentReports')
         dispatch({
                type: GET_TOUR_NAMENT_SUCCESS,
                payload: response.data
            })
        }catch{
            dispatch({
                type: GET_TOUR_NAMENT_FILED,
                error: { message: "Something went wrong." }
             })
        }
    }
}