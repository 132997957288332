import React, { useState, useEffect, useLayoutEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { debounce } from "debounce";
import { TopContant } from "../components/TopContant/TopContant";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ErrorBoundary } from "../components/ErrorBoundary/ErrorBoundary";
import {
    Dashboard,
    Navbar,
    Menu,
    BurgerMenu,
    Tournaments,
    UserGifts,
    Statistics,
    Offers,
    Notifications,
    ActiveGames,
    UserPage,
    TeamGame,
} from "../pages/index";
import "./style.scss";

export function RootPage() {
    const [isShowModal, setIsShowModal] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.screen.width);
    const state = useSelector((state) => state.authReducer);

    const { role, error, user } = state;

    const handleWindowResize = debounce(() => {
        setWindowWidth(window.screen.width);
    }, 300);

    useLayoutEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    const handleShowModal = () => {
        setIsShowModal(!isShowModal);
    };

    let history = useHistory();
    useEffect(() => {
        if (!role) {
            history.push("/sign-in");
        }
    }, [role]);

    const dispatch = useDispatch();
    useEffect(() => {
        return history.listen((location) => {
            dispatch({ type: "CHANGE_LOCATION_TRUE" });
        });
    }, []);

    return (
        <div className="RootPage-container">
            <Navbar handleShowModal={handleShowModal} />
            {windowWidth < 1200 ? (
                <BurgerMenu
                    isShowModal={isShowModal}
                    windowWidth={windowWidth}
                />
            ) : (
                <Menu isShowModal={isShowModal} />
            )}
            <div
                className="main-container"
                style={
                    (windowWidth < 1199 ? { marginLeft: 0 } : null) ||
                    (isShowModal ? { marginLeft: 50 } : { marginLeft: 180 })
                }
            >
                <div className="al-content">
                    <TopContant />
                    <ErrorBoundary>
                        <Switch>
                            <Route
                                path="/dashboard"
                                component={Dashboard}
                                exact
                            />
                            <Route
                                path="/tournaments"
                                component={Tournaments}
                                exact
                            />
                            <Route
                                path="/usergifts"
                                component={UserGifts}
                                exact
                            />
                            <Route
                                path="/statistics"
                                component={Statistics}
                                exact
                            />
                            <Route
                                path="/teamGame"
                                component={TeamGame}
                                exact
                            />
                            <Route path="/offers" component={Offers} exact />
                            <Route
                                path="/notifications"
                                component={Notifications}
                                exact
                            />
                            <Route
                                path="/active"
                                component={ActiveGames}
                                exact
                            />
                            <Route path="/user" component={UserPage} exact />
                        </Switch>
                    </ErrorBoundary>
                </div>
            </div>
        </div>
    );
}
