import React from 'react'
import Radio from '@material-ui/core/Radio';

export function RadioComp({ handleChange, checked, styleRadeo }) {
 

  return (
    <Radio
      checked={checked}
      onChange={handleChange}
      style={styleRadeo}
      color="default"
      name="radio-button-demo"
      inputProps={{ 'aria-label': 'E' }}
      size="small"
    />
  )
}
