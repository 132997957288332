import { api, axiosInstanceTeam } from "../service";

export const teamGameList_req = async () => {
    const response = await axiosInstanceTeam.get("/list", {
        page: 1,
        limit: 25,
    });
    return response.data;
};
export const teamGameMessages_req = async (message, teamId) => {
    console.log("message", message, teamId);
    const response = await api.post("/sendMessage", {
        message: message,
        teamIds: teamId,
    });
    return response.data;
};
