import {
    NOTIFICATION_START,
    NOTIFICATION_SUCCESS,
    NOTIFICATION_FILED,
    UPDATE_NOT_START,
    UPDATE_NOT_SUCCESS,
    UPDATE_NOT_FILED,
    CREATE_NOT_START,
    CREATE_NOT_SUCCESS,
    CREATE_NOT_FILED,
    DELETE_NOT_START,
    DELETE_NOT_SUCCESS,
    DELETE_NOT_FILED,
    SEND_NOT_START,
    SEND_NOT_SUCCESS,
    SEND_NOT_FILED,
    CREATE_NOT_LIST,
    DELETE_NOT_LIST,
    UPDATE_NOT_LIST
} from "../action-types/actionTypes";
import { api } from '../../api/service'


export const notificationCreate = data => {
  return async dispatch => {
        dispatch({
            type: NOTIFICATION_START,
        });
        try {
            const response = await api.post('/getNotifications', data);
   dispatch({
                type: NOTIFICATION_SUCCESS,
                payload: response.data
            });
        } catch (e) {
            dispatch({
                type: NOTIFICATION_FILED,
                error: { message: "Something went wrong." }
            });
        }
    };
}


// /updateNotification 
export const updateNotificationCreate = data => {
    return async dispatch => {
        dispatch({
            type: UPDATE_NOT_START
        })
        try {
            const response = await api.post('/updateNotification', data )
        dispatch({
                type: UPDATE_NOT_SUCCESS,
                payload: response.data
            })
        }catch (e){
            dispatch({
                type: UPDATE_NOT_FILED,
                error: { message: "Something went wrong." }
            })
        }
    }
}
// /sendNotification 
export const sendNotificationCreate = data => {
    return async dispatch => {
        dispatch({
            type: SEND_NOT_START
        })
        try {
            const response = await api.post('/sendNotification', data )
        dispatch({
                type: SEND_NOT_SUCCESS,
                payload: response.data
            })
        }catch (e){
            dispatch({
                type: SEND_NOT_FILED,
                error: { message: "Something went wrong." }
            })
        }
    }
}

export function addUpdateNotificationCreate(data){
  return ({type: UPDATE_NOT_LIST, payload: data })
}

// /createNotification

export const createNotificationCreate = data => {
    return async dispatch => {
        dispatch({
            type: CREATE_NOT_START
        })
        try {
         const response = await api.post('/createNotification', data )
        dispatch({
                type: CREATE_NOT_SUCCESS,
                payload: response.data
            })
        }catch (e){
            dispatch({
                type: CREATE_NOT_FILED,
                error: { message: "Something went wrong." }
            })
        }
    }
}

export function  addListCreateNotificationCreate(data) {
    return ({type: CREATE_NOT_LIST, payload: data })
}



export const deleteNotificationCreate = data => {
    return async dispatch => {
        dispatch({
            type: DELETE_NOT_START
        })
        try {
        const response = await api.post('/deleteNotification', data )
      dispatch({
                type: DELETE_NOT_SUCCESS,
                payload: response.data
            })
        }catch (e){
            dispatch({
                type: DELETE_NOT_FILED,
                error: { message: "Something went wrong." }
            })
        }
    }
}
export function deleteListNotificationCreate(id){
    return ({type: DELETE_NOT_LIST, payload: id })
}