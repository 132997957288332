import React from 'react'
import CachedIcon from '@material-ui/icons/Cached';
import './style.scss'

export function SearchLoudingIcon({ fontSize, styles }) {

    return (
        <div className="search-loading-icon-wrapper" style={styles}>
            <CachedIcon fontSize={fontSize} />
        </div>
    )
}
