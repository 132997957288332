import {
    GET_TOUR_NAMENT_START,
    GET_TOUR_NAMENT_SUCCESS,
    GET_TOUR_NAMENT_FILED,
} from "../action-types/actionTypes";

const initialState = {
    loading: false,
    tournament: null,
    error: null,
}

export function tournamentReducer(state = initialState, action) {
    switch (action.type) {
        case GET_TOUR_NAMENT_START:
            return {
                ...state,
                loading: true
            }
        case GET_TOUR_NAMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                tournament: action.payload,
               }
        case GET_TOUR_NAMENT_FILED:
            return {
                ...state,
                loading: false,
                error: 'samething went wrong',
            }
        default:
            return state;
    }
}