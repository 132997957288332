import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Pagination } from '../Pagination/Pagination';
import AddIcon from '@material-ui/icons/Add';
import { getGameDetalisCreate } from '../../store/actions/getGameDetalis';
import GameDetalis from '../GameDetalis/GameDetalis';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { loaderGif } from '../../assets';
import './style.scss';



export function UserGame({ userGameState }) {

    function getDays(day) {
        let d = new Date(day);
        let date = d.toDateString() + ' ' + d.toTimeString();
        date = date.slice(3).substring(0, 22);
        return date;
    }

    const stateGameDetalis = useSelector(state => state.gameDetalisReduser);
    const { loading, gameDetalis } = stateGameDetalis;


    const { userId } = userGameState;

    useEffect(() => {
        let tepmTodo = userGameState.games && userGameState.games.sort((a, b) => new Date(a.started) < new Date(b.started) ? 1 : -1);
        if (userGameState) setToto(tepmTodo);

    }, [userGameState]);

    const newChips = gameDetalis && userId && gameDetalis.game.users.filter(el => el.userId === userId.toString())[0] && gameDetalis.game.users.filter(el => el.userId === userId.toString())[0].newChips &&
        gameDetalis.game.users.filter(el => el.userId === userId.toString())[0].newChips.red;
    const oldChips = gameDetalis && userId && gameDetalis.game.users.filter(el => el.userId === userId.toString())[0] && gameDetalis.game.users.filter(el => el.userId === userId.toString())[0].oldChips &&
        gameDetalis.game.users.filter(el => el.userId === userId.toString())[0].oldChips.red;


    const [todos, setToto] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectPage, setSelectPage] = useState(0);
    const [isShowDetalis, setShowDetalis] = useState([]);
    const [isShowUser, setShowUser] = useState([]);
    const [gameData, setGameData] = useState([]);
    const [value, setValue] = useState({ type: '', status: '' });
    const { type, status } = value;

    const handleChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value
        });
    };

    const handleClickPlus = (game_id) => {
        setShowUser([...isShowUser, game_id]);
    };
    const handleClickMinus = (game_id) => {
        isShowUser.splice(isShowUser.indexOf(game_id), 1);
        setShowUser([...isShowUser]);
    };




    let pageCount = 0;
    if (todos) {
        pageCount = Math.ceil(todos.length / 15);
    }

    const indexOfLastTodo = currentPage * 15;
    const indexOfFirstTodo = indexOfLastTodo - 15;


    let currentTodos = [];
    if (todos) {
        currentTodos = todos.slice(indexOfFirstTodo, indexOfLastTodo);
    }
    const dispatch = useDispatch();

    const handleClick = gameId => {
        if (!isShowDetalis.includes(gameId)) {
            setShowDetalis([...isShowDetalis, gameId]);
            dispatch(getGameDetalisCreate({ gameId: gameId }));
        } else {
            isShowDetalis.splice(isShowDetalis.indexOf(gameId), 1);
            setShowDetalis([...isShowDetalis]);
            let removGameDataItem = gameData.filter(el => el._id !== gameId);
            setGameData(removGameDataItem);
        }

    };
    console.log('gamedata', gameData);

    useEffect(() => {
        if (gameDetalis) {
            currentTodos.map(el => {
                if (el.id === gameDetalis.game._id) {
                    el.createGameDetalis = gameDetalis.game;
                }
                return el;
            });
            currentTodos.push(gameDetalis.game);
            setGameData([...gameData, gameDetalis.game]);
        }
    }, [gameDetalis]);

    useEffect(() => {
        setGameData([]);
    }, []);

    let filterListType = todos && todos.length > 0 && todos.filter(name => {
        return type.length > 0 && name.roomType.toLowerCase().indexOf(type.toLowerCase()) >= 0;

    });

    useEffect(() => {
        if (userGameState) {
            if (filterListType && filterListType.length) {
                setToto(filterListType);
            } else {
                setToto(userGameState.games);
            }
        }
    }, [value]);

    //sort
    const [isSortStarted, setSortStarted] = useState(false);
    const handleSortStarted = () => {
        setSortStarted(!isSortStarted);
        if (isSortStarted) {
            todos.sort((a, b) => new Date(a.started) < new Date(b.started) ? 1 : -1);
        } else {
            todos.sort((a, b) => new Date(a.started) > new Date(b.started) ? 1 : -1);
        }
    };

    const [isSortFinished, setSortFinished] = useState(false);
    const handleSortFinished = () => {
        setSortFinished(!isSortFinished);
        if (isSortFinished) {
            todos.sort((a, b) => new Date(a.ended) > new Date(b.ended) ? 1 : -1);
        } else {
            todos.sort((a, b) => new Date(a.ended) < new Date(b.ended) ? 1 : -1);
        }
    };



    //render user Game  list
    const renderList = currentTodos.map((row, index) => {

        return <Fragment key={row.id}  ><tr className={row.active ? 'unfinished-game' : null}>
            <td>
                <button onClick={(e) => handleClick(row.id)}>
                    {isShowDetalis.includes(row.id) ?
                        <RemoveIcon fontSize='small' htmlColor='white' /> :
                        <AddIcon fontSize='small' htmlColor='white' />}
                </button>
            </td>
            <td style={{ display: 'flex' }}>
                <span className={isShowDetalis.includes(row.id) &&
                    row.createGameDetalis && row.createGameDetalis.users && row.createGameDetalis.users.length > 0 &&
                    row.createGameDetalis.users.filter(el => el.connected === false && el.userId === userId + '')[0] &&
                    row.createGameDetalis.users.filter(el => el.connected === false && el.userId === userId + '')[0].connected === false ?
                    'userLeave' : ''}>{row.roomType}</span>
                <span style={{ marginLeft: 5 }}>
                    {isShowDetalis.includes(row.id) && 'new chips : ' + (newChips || 0) + ', old chips : ' + (oldChips || 0)}
                </span>
            </td>

            <td>{(row.chargeBack && "charge backed") ||
                (row.active && 'active') ||
                (row.win && 'win') ||
                (!row.win && 'lost')
            }</td>
            <td> {getDays(row.started)} </td>
            <td> {row.ended ? getDays(row.ended) : '-'} </td>
        </tr>
            {gameDetalis && isShowDetalis.includes(row.id) &&
                <tr><td colSpan='5' style={{ padding: 0 }} >
                    {loading ? <img style={{ height: 45 }} src={loaderGif} alt="gif" /> :
                        <GameDetalis
                            gameDetalis={gameDetalis}
                            gameData={gameData}
                            isShowUser={isShowUser}
                            handleClickPlus={handleClickPlus}
                            handleClickMinus={handleClickMinus}
                            id={row.id}
                        />}</td></tr>}
        </Fragment>;
    });

    const onChangePage = i => {
        setSelectPage(i.selected);
        setCurrentPage(i.selected + 1);
    };
    console.log('renderlist', renderList);
    return (
        <div className='userGame-container'>
            <div className="tab-content">
                <table className="table">
                    <thead>
                        <tr className="sortable">
                            <th>Detalis </th>
                            <th>Type </th>
                            <th>Status</th>
                            <th onClick={handleSortStarted}>Started {!isSortStarted ?
                                <ArrowDropDownIcon style={{ verticalAlign: 'middle' }} fontSize={'small'} /> :
                                <ArrowDropUpIcon style={{ verticalAlign: 'middle' }} fontSize={'small'} />}</th>
                            <th onClick={handleSortFinished}>Finished {!isSortFinished ?
                                <ArrowDropDownIcon style={{ verticalAlign: 'middle' }} fontSize={'small'} /> :
                                <ArrowDropUpIcon style={{ verticalAlign: 'middle' }} fontSize={'small'} />}</th>
                        </tr>
                        <tr>
                            <th className='th' colSpan='2' >
                                <input placeholder="Type" onChange={handleChange} value={type} name='type' className="input-sm form-control search-input" type="text" /></th>
                            <th className='th' >
                                <input placeholder="Status" onChange={handleChange} value={status} name='status' className="input-sm form-control search-input" type="text" /></th>
                            <th className='th' >
                                <input placeholder="Started" onChange={handleChange} className="input-sm form-control search-input" type='text' /></th>
                            <th className='th' >
                                <input placeholder="Finished" onChange={handleChange} className="input-sm form-control search-input" type="text" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderList}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="6" className="text-center" >
                                <div className="nav">
                                    {pageCount > 1 && <Pagination
                                        selectPage={selectPage}
                                        pageCount={pageCount}
                                        onChangePage={(i) => onChangePage(i)}
                                    />}
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
}

