import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { UserGame } from '../UserGame/UserGame';
import { Pagination } from '../Pagination/Pagination';
import { redChipIcon, yellowChipIcon, greenChipIcon } from '../../assets';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { vipChip } from '../../assets';
import { getDays } from '../Methods/Methods';
import { Notes } from '../Notes/Notes';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import './style.scss';


export const TransactionsPurchase = ({ state, userGameState }) => {


    const { userTrPurchData } = state;
    const [isActive, setActive] = useState('transactions');
    const [todos, setToto] = useState(userTrPurchData);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectPage, setSelectPage] = useState(0);
    const [value, setValue] = useState({ type: '', amount: '' });
    const { type, amount } = value;

    useEffect(() => {
        userTrPurchData && userTrPurchData.sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1);
        setToto(userTrPurchData);
    }, [userTrPurchData]);


    const handleClickTab = (tab) => {
        setActive(tab);
    };

    const indexOfLastTodo = currentPage * 15;
    const indexOfFirstTodo = indexOfLastTodo - 15;


    let currentTodos = [];
    if (todos) {
        currentTodos = todos.slice(indexOfFirstTodo, indexOfLastTodo);
    }
    const handleChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value
        });
    };


    let filterListType = todos && todos.filter(name => {
        return type.length > 0 && name.type.toLowerCase().indexOf(type.toLowerCase()) >= 0;
    });
    let filterListAmount = todos && todos.filter(name => {
        return amount.length > 0 && parseInt(name.amount) >= parseInt(amount);
    });


    useEffect(() => {
        if (filterListType && filterListType.length) {
            setToto(filterListType);
        }
        else if (filterListAmount && filterListAmount.length) {
            setToto(filterListAmount);
        } else {
            setToto(userTrPurchData);
        }
    }, [value]);

    let pageCount = 0;

    if (todos && todos.length) {
        pageCount = Math.ceil(todos.length / 15);
    }


    const [isSortcreatedAt, setSortcreatedAt] = useState(false);
    const handleSortcreatedAt = () => {
        setSortcreatedAt(!isSortcreatedAt);
        if (isSortcreatedAt) {
            todos.sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1);
        } else {
            todos.sort((a, b) => new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1);
        }
    };

    const renderList = currentTodos.map((row, index) => {
        return <tr key={index}>
            <td>
                <span>{row.type === 'adminPanel' ?
                    ('adminPanel - ' + (row.admin && row.admin.username ? row.admin.username : '')) : row.type}  {' '}

                    {row.money && row.status && <CheckIcon fontSize='small' style={{ verticalAlign: 'middle' }} />}
                    {row.money && !row.status && <CloseIcon fontSize='small' style={{ verticalAlign: 'middle' }} />}
                </span>
            </td>
            <td>
                {row.chipType === null ? <RemoveCircleIcon className='no-icon' /> :
                    (row.chipType === 'discount' ? `x${row.amount}`.slice(0, 1) : <img src={
                        (row.chipType === 'red' ? redChipIcon : null) ||
                        (row.chipType === 'yellow' ? yellowChipIcon : null) ||
                        (row.chipType === 'green' ? greenChipIcon : null) ||
                        (row.chipType === "vip" || row.chipType === "112" ||
                            row.chipType === "111" || row.chipType === "113" ? vipChip : null)
                    }
                        className='chip' alt="icon" />)}
                <span>{row.chipType === "vip" ? row.amount / 1000 / 3600 / 24 + ' days' : row.amount}</span>
            </td>
            <td>
                <span>{getDays(row.createdAt)}</span>
            </td>
        </tr>;
    });
    const pageNumbers = [];
    if (todos) {
        for (let i = 1; i <= Math.ceil(todos.length / pageCount); i++) {
            pageNumbers.push(i);
        }
    }
    const onChangePage = i => {
        setSelectPage(i.selected);
        setCurrentPage(i.selected + 1);

    };

    return (
        <div className='transactionsPurchase-container'>
            <ul className="nav-tabs">
                <li style={{ borderTopLeftRadius: 5 }} className={isActive === 'transactions' ? 'active' : null}
                    onClick={(e) => handleClickTab('transactions')} >Transactions</li>
                <li className={isActive === 'user-games' ? 'active' : null}
                    onClick={() => handleClickTab('user-games')} >User Games</li>
                <li className={isActive === 'notes' ? 'active' : null}
                    onClick={() => handleClickTab('notes')} >Notes</li>
            </ul>
            {isActive === 'transactions' && <div className="tab-content">
                <table className="table">
                    <thead>
                        <tr className="sortable">
                            <th>Type </th>
                            <th>Amount</th>
                            <th onClick={handleSortcreatedAt}>Date {!isSortcreatedAt ?
                                <ArrowDropDownIcon style={{ verticalAlign: 'middle' }} fontSize={'small'} /> :
                                <ArrowDropUpIcon style={{ verticalAlign: 'middle' }} fontSize={'small'} />}</th>
                        </tr>
                        <tr>
                            <th className='th' >
                                <input onChange={handleChange} name='type' placeholder="Type" className="input-sm form-control search-input" type="text" /></th>
                            <th className='th' >
                                <input onChange={handleChange} name='amount' placeholder="Amount" className="input-sm form-control search-input" type='number' /></th>
                            <th className='th' >
                                <input name='date' placeholder="Date" className="input-sm form-control search-input" type="text" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderList}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="6" className="text-center" >
                                <div className="nav">
                                    {pageCount > 1 && <Pagination
                                        selectPage={selectPage}
                                        pageCount={pageCount}
                                        onChangePage={(i) => onChangePage(i)}
                                    />}
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>}
            {isActive === 'user-games' && <UserGame userGameState={userGameState} />}
            {isActive === 'notes' && <Notes />}
        </div>
    );
};










































